import React from "react";

const GoogleMarker = ({ children }) => {
  if (!children)
    return (
      <div
        className="google-marker"
        style={{
          width: `20px`,
          height: `20px`,
        }}
      >
        1
      </div>
    );

  return <>{children}</>;
};

export default GoogleMarker;
