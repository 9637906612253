import React, { useEffect, useState } from "react";
import { Table, Form, Select, Input, Layout, Alert, Spin } from "antd";
import moment from "moment";

import { Link } from "@reach/router";
import queryString from "query-string";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "react-apollo";
import { StructuresComponent, GET_SEARCH_OPTIONS } from "../Admin/UsersScreen";
import { STRUCTURE_MAP } from "../Reporting/ReportingStructure";
import { Flex } from "rebass";

import config from "../../common/config";

import AdminScreen from "../Admin/AdminScreen";

const { Content } = Layout;

const columns = [
  {
    title: config[process.env.REACT_APP_SITE_ID].voterId,
    dataIndex: "registrationNumber",
    key: "registrationNumber",
    defaultSortOrder: "descend",
  },
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName",
    sortDirections: ["descend"],
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName",
    defaultSortOrder: "descend",
  },
  {
    title: "Constituency",

    dataIndex: "pd.constituency.name",
    defaultSortOrder: "descend",
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Link to={`/voter/${record.registrationNumber}`}>View Profile</Link>
    ),
  },
];

const Voters = ({ params, code, ...props }) => {
  const {
    data: user,
    loading: viewerLoader,
    error,
  } = useQuery(GET_SEARCH_OPTIONS);
  const { data: taglist, ...tagsQuery } = useQuery(GET_TAGS);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [getVoterDetails, { loading, data = [] }] = useLazyQuery(GET_VOTERS);

  const { total, ...rest } = queryString.parse(props.location.search, {
    arrayFormat: "index",
  });

  const [structure, setStructure] = useState({
    [STRUCTURE_MAP[props.structure]]: code,
    tags: rest.tags,
  });

  const onSetStructure = (payload) => {
    setPagination({
      current: 1,
      pageSize: 10,
    });

    let newPayload = payload;

    if (payload.electoralDivision) {
      newPayload.electoralDivision = Number(payload.electoralDivision);
    }

    if (payload.region) {
      newPayload.region = Number(payload.region);
    }

    if (payload.constituency) {
      newPayload.constituency = Number(payload.constituency);
    }

    setStructure({
      ...structure,
      ...newPayload,
    });
  };

  useEffect(
    () => {
      let newInput = rest;

      if (rest.electoralDivision) {
        newInput.electoralDivision = Number(rest.electoralDivision);
      }

      if (rest.region) {
        newInput.region = Number(rest.region);
      }

      if (rest.constituency) {
        newInput.constituency = Number(rest.constituency);
      }

      getVoterDetails({
        variables: {
          input: {
            ...structure,
            limit: pagination.pageSize,
            offset: (pagination.current - 1) * pagination.pageSize,
            ...newInput,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [structure, pagination]
  );

  useEffect(
    () => {
      if (data && data.getCanvasTagVoterDetails) {
        setPagination({
          ...pagination,
          total: data.getCanvasTagVoterDetails.count,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  if (viewerLoader || tagsQuery.loading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Spin />
      </Flex>
    );
  }

  const { getCanvasTagVoterDetails: { rows = [] } = { rows: [] } } = data;

  return (
    <AdminScreen title="Voters">
      <Layout>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {error ? (
            <Alert type="error">
              <p>
                An error has occurded. Please refresh and if the problem
                persists, contact support.
              </p>
            </Alert>
          ) : (
            <>
              <StructuresComponent
                onSelect={onSetStructure}
                value={structure}
                defaultRole={"PD"}
                structures={user.viewer.structures}
              />

              <Flex>
                <Form.Item
                  className="w-25"
                  label="First Name"
                  style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                >
                  <Input
                    placeholder="Search by first name"
                    value={structure.firstName}
                    onChange={(e) =>
                      onSetStructure({ firstName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  className="w-25"
                  label="Last name"
                  style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                >
                  <Input
                    placeholder="Search by last name"
                    value={structure.lastName}
                    onChange={(e) =>
                      onSetStructure({ lastName: e.target.value })
                    }
                  />
                </Form.Item>
                {taglist && (
                  <Form.Item
                    label="Tags"
                    className="w-25"
                    style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select tags"
                      value={structure.tags}
                      onChange={(value) => {
                        onSetStructure({
                          tags: value,
                        });
                      }}
                      optionLabelProp="label"
                      style={{ width: "100%" }}
                    >
                      {taglist.tags.map((tag) => (
                        <Select.Option value={tag.id} label={tag.name}>
                          {tag.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Flex>
              <Table
                loading={loading}
                className="table-tags"
                rowSelectionType="none"
                expandedRowRender={(record) => (
                  <CanvassHistory id={record.id} structure={structure} />
                )}
                dataSource={rows}
                columns={columns}
                pagination={pagination}
                onChange={(pagination, filters, sorter) => {
                  setPagination(pagination);
                }}
              />
            </>
          )}
        </Content>
      </Layout>
    </AdminScreen>
  );
};

export default Voters;

const GET_VOTERS = gql`
  query ($input: ReportingQueryInput) {
    getCanvasTagVoterDetails(input: $input) {
      rows {
        registrationNumber
        firstName
        middleName
        lastName
        id

        pd: pollingDivision {
          constituency {
            name
          }
        }
        tags {
          name
        }
      }
      count
    }
  }
`;

const GET_VOTER_HISTORY = gql`
  query ($input: ReportingQueryInput, $id: ID!) {
    getCanvassHistory(input: $input, id: $id) {
      tag
      date
      user
    }
  }
`;

const GET_TAGS = gql`
  query {
    tags {
      id
      name
      code
      category
    }
  }
`;

const CanvassHistory = ({ id, structure }) => {
  const {
    data: canvassHistory,
    loading,
    error,
  } = useQuery(GET_VOTER_HISTORY, {
    variables: {
      id,
      input: structure,
    },
  });
  if (error) return <Alert message={error.message} type="error" />;

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (record) => {
        console.log(record);
        return <span>{moment(new Date(record)).format("YYYY/MM/DD")}</span>;
      },
    },
    { title: "Tag", dataIndex: "tag", key: "tag" },
    {
      title: "User",
      key: "user",
      dataIndex: "user",
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={canvassHistory?.getCanvassHistory || []}
      pagination={canvassHistory?.getCanvassHistory.length > 10}
    />
  );
};
