import React, { useContext, useState } from "react";
import { navigate } from "@reach/router";
import { Spin, Form, Input, Button, Tabs, Select } from "antd";
import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { Box, Flex } from "rebass";

import { ResultsContext } from "./ResultsProvider";

import { StructuresComponent, GET_SEARCH_OPTIONS } from "../Admin/UsersScreen";
import config from "../../common/config";
import AdminScreen from "../Admin/AdminScreen";

const { TabPane } = Tabs;

const SearchScreen = () => {
  const { setSearch } = useContext(ResultsContext);
  const { loading: searchLoading, data } = useQuery(GET_SEARCH_OPTIONS);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    registrationNumber: "",
  });
  const [loading] = useState(false);

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onSearch = () => {
    setSearch(values);
    navigate("/results");
  };

  if (searchLoading || !data) return <Spin />;

  const {
    viewer: { structures },
  } = data;

  return (
    <AdminScreen title={"Canvass"}>
      {loading ? (
        <Box py={[2]} alignSelf="center">
          <Spin />
        </Box>
      ) : (
        <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Name" key="1">
              <PersonSearch
                structures={structures}
                onChange={onSetValues}
                values={values}
                onSearch={onSearch}
              />
            </TabPane>
            <TabPane tab="Voter Number" key="2">
              <RRCSearch
                onChange={onSetValues}
                values={values}
                onSearch={onSearch}
              />
            </TabPane>
          </Tabs>
        </Box>
      )}
    </AdminScreen>
  );
};

export const PollList = ({
  electoralDivision,
  constituency,
  pd,
  region,
  showTitle = true,
  code = "id",
  ...restProps
}) => {
  const query = {};

  if (constituency) {
    query.constituencyCode = constituency.toString();
  }

  if (region) {
    query.regionCode = region.toString();
  }

  if (electoralDivision) {
    query.electoralDivision = parseInt(electoralDivision);
  }

  const { data, loading } = useQuery(GET_POLLS, {
    variables: {
      input: query,
    },
  });

  if (loading || !data)
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spin />
      </Flex>
    );

  const { polls } = data;

  console.log(restProps, polls, code);
  return (
    <Form.Item
      label={showTitle ? "Polling Divisions" : null}
      style={{ minWidth: "250px" }}
    >
      <Select style={{ width: "100%" }} value={pd} {...restProps}>
        <Select.Option value={null}>All</Select.Option>
        {polls
          .sort((a, b) => (a.pdCode >= b.pdCode ? 1 : -1))
          .map((p) => (
            <Select.Option key={p[code]} value={p[code]}>
              PD {p.pdName || p.pdCode}: {p.constituency.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

const GET_POLLS = gql`
  query getPollingDivisions($input: PollingDivisionInput) {
    polls(input: $input) {
      id
      pdCode
      pdName
      constituency {
        name
      }
    }
  }
`;

export const LoadRegions = ({ national, render, ...rest }) => {
  let query = {};
  if (national) {
    query.country = national;
  }

  const { loading, data } = useQuery(GET_REGIONS, {
    variables: {
      country: national,
    },
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.regions);
};

export const LoadConstituencies = ({ region, render }) => {
  let query = {};
  if (region) {
    query.region = parseInt(region);
  }
  const { loading, data } = useQuery(GET_CONSTITUENCIES, {
    variables: query,
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.constituencies);
};

export const LoadElectoralDivisions = ({ region, constituency, render }) => {
  const { loading, data } = useQuery(GET_ELECTORAL_DIVISIONS, {
    variables: {
      region: region ? parseInt(region) : null,
      constituency: constituency ? parseInt(constituency) : null,
    },
  });

  if (!data || loading) return <Spin />;
  return render(loading, data.electoralDivisions);
};

const StreetName = ({
  pollingDivision,
  onChange,
  value,
  pollingDivisions = [],
}) => {
  if (!pollingDivision && !pollingDivisions.length) return null;

  return (
    <Form.Item label="Find Street Name" style={{ fontWeight: 700 }}>
      <Input
        placeholder="Search by street name in polling division"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Form.Item>
  );
};

export const PersonSearch = ({
  values,
  onChange,
  onSearch,
  structures,
  vertical = false,
  components = {},
}) => {
  const component_structures = (structure) => ({
    national: {
      countries: [
        {
          code: config[process.env.REACT_APP_SITE_ID].country.code,
          name: config[process.env.REACT_APP_SITE_ID].country.name,
        },
      ],
      regions: [],
      constituencies: [],
      electoralDivisions: [],
      pollingDivisions: [],
    },
    regions: {
      regions: structure.regions,
      constituencies: [],
      electoralDivisions: [],
      pollingDivisions: [],
    },
    constituencies: {
      regions: [],
      constituencies: structure.constituencies,
      electoralDivisions: [],
      pollingDivisions: [],
    },
    electoralDivisions: {
      regions: [],
      constituencies: [],
      electoralDivisions: structure.electoralDivisions,
      pollingDivisions: [],
    },
    pollingDivisions: {
      regions: [],
      constituencies: [],
      electoralDivisions: [],
      pollingDivisions: structure.pollingDivisions,
    },
  });

  const currentStructure = Object.keys(structures).reduce((result, item) => {
    if (structures[item] && structures[item].length && !result) {
      result = {
        type: item,
        options: component_structures(structures)[item],
      };
    }

    return result;
  }, null);

  return (
    <>
      <Form.Item label="First Name">
        <Input
          placeholder="Search by first name"
          value={values.firstName}
          onChange={(e) => onChange({ firstName: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="Last name" style={{ fontWeight: 700 }}>
        <Input
          placeholder="Search by last name"
          value={values.lastName}
          onChange={(e) => onChange({ lastName: e.target.value })}
        />
      </Form.Item>
      <StructuresComponent
        onSelect={(value) => onChange(value)}
        value={values}
        exclude={["RegionComponent", "ElectoralComponent"]}
        defaultRole={"CANVASSER"}
        structures={structures}
        vertical
        components={components}
      />

      <StreetName
        {...currentStructure.options}
        pollingDivision={values.electoralDivision}
        value={values.streetName}
        onChange={(value) => onChange({ streetName: value })}
      />
      <Form.Item>
        <Button
          block
          type="primary"
          size="large"
          htmlType="submit"
          className="login-form-button"
          onClick={onSearch}
        >
          Search
        </Button>
      </Form.Item>
    </>
  );
};

export const RRCSearch = ({ values, onChange, onSearch }) => {
  return (
    <>
      <Form.Item label={config[process.env.REACT_APP_SITE_ID].voterId}>
        <Input
          placeholder={`Enter voter ${
            config[process.env.REACT_APP_SITE_ID].voterId
          }`}
          value={values.registrationNumber}
          onChange={(e) => onChange({ registrationNumber: e.target.value })}
        />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          className="login-form-button"
          size="large"
          onClick={onSearch}
        >
          Search
        </Button>
      </Form.Item>
    </>
  );
};

export default SearchScreen;

const GET_REGIONS = gql`
  query getRegions($country: String) {
    regions(country: $country) {
      id
      code
      name
    }
  }
`;

const GET_ELECTORAL_DIVISIONS = gql`
  query getElectoralDivisions($region: Int, $constituency: Int) {
    electoralDivisions(region: $region, constituency: $constituency) {
      id
      code
      name
    }
  }
`;

const GET_CONSTITUENCIES = gql`
  query getConstituencies($region: Int) {
    constituencies(region: $region) {
      id
      code
      name
    }
  }
`;
