import React, { useState, useEffect } from "react";
import { Box, Flex } from "rebass";
import { Spin, Button, Col, Select, message, Alert } from "antd";
import { navigate } from "@reach/router";
import GotvContainer from "../Gotv/GotvContainer";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import GotvStructures from "./GotvStructures";

const { Option } = Select;

const DashBoard = () => {
  const { loading, data, error } = useQuery(GET_ELECTION);

  const [election, setElection] = useState(undefined);

  useEffect(() => {
    if (loading === false && data) {
      const { elections } = data;

      if (elections.length) {
        setElection(elections[0].code);
      }
    }
  }, [loading, data]);

  if (loading || !data) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spin />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Alert message="Something went wrong. Please contact support for assistance" />
      </Flex>
    );
  }

  const { elections } = data;

  return (
    <GotvContainer>
      {(structure, reset) => (
        <Col style={{ width: "100%" }}>
          {election ? (
            <>
              {structure.pd ? <GotvStructures structure={structure} /> : null}
              <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                <Select
                  style={{ width: "100% " }}
                  onChange={(value) => setElection(value)}
                  placeholder="Select election"
                  defaultValue={election}
                >
                  {elections.map((election) => (
                    <Option value={election.code}>{election.name}</Option>
                  ))}
                </Select>
              </Box>

              {/*<Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                <Button
                  type="primary"
                  block
                  disabled={
                    !(structure.constituency || structure.pd || structure.ps)
                  }
                  size="large"
                  onClick={() => {
                    if (!election) {
                      message.warning("Please select a election");
                    } else {
                      navigate(`/gotv/inside/${election}`);
                    }
                  }}
                >
                  Inside
                </Button>
                </Box>*/}

              <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                <Button
                  type="primary"
                  block
                  disabled={
                    !(structure.constituency || structure.pd || structure.ps)
                  }
                  size="large"
                  onClick={() => {
                    if (!election) {
                      message.warning("Please select a election");
                    } else {
                      navigate(`/gotv/outside/${election}`);
                    }
                  }}
                >
                  Outside
                </Button>
              </Box>

              <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                <Button
                  type="primary"
                  block
                  disabled={
                    !(structure.constituency || structure.pd || structure.ps)
                  }
                  size="large"
                  onClick={() => {
                    if (!election) {
                      message.warning("Please select a election");
                    } else {
                      navigate(`/gotv/calling/${election}`);
                    }
                  }}
                >
                  Calling
                </Button>
              </Box>
              <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                <Button
                  type="primary"
                  block
                  size="large"
                  disabled={
                    !(
                      structure.national ||
                      structure.constituency ||
                      structure.pd ||
                      structure.ps
                    )
                  }
                  onClick={() => navigate(`/gotv/turnout/${election}`)}
                  style={{
                    backgroundColor: "#1b8b3f",
                    color: "white",
                    borderColor: "white",
                  }}
                >
                  Turnout Statistics
                </Button>
              </Box>
              <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                <Button
                  type="primary"
                  block
                  size="large"
                  onClick={() => {
                    reset();
                  }}
                  style={{
                    backgroundColor: "#e73232",
                    color: "white",
                    borderColor: "white",
                  }}
                >
                  Select Other Structure
                </Button>
              </Box>
            </>
          ) : (
            <Flex justifyContent="center" alignItems="center" m={[3]}>
              <Alert
                message="No elections loaded. Please contact support for assistance"
                type="warning"
              />
            </Flex>
          )}
        </Col>
      )}
    </GotvContainer>
  );
};

export default DashBoard;

const GET_ELECTION = gql`
  {
    elections {
      id
      name
      code
    }
  }
`;
