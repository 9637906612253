import React, { useState } from "react";

export const ResultsContext = React.createContext();

const ResultsProvider = ({ data, children }) => {
  const [results, setResults] = useState([]);
  const [voter, setVoter] = useState(null);
  const [search, setSearch] = useState({});

  return (
    <ResultsContext.Provider
      value={{
        results,
        setResults,
        voter,
        setVoter,
        search,
        setSearch
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
};

export default ResultsProvider;
