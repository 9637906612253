import config from "./config";

const { parsePhoneNumber } = require("libphonenumber-js");

export const mapper = (data, map) => {
  const newData = {};
  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };

  Object.keys(map).forEach((key) => {
    const property = map[key];
    const obj = getNestedObject(data, property);
    if (obj) {
      newData[key] = obj;
    }
  });

  return newData;
};

export const validateForm = (form, values) => {
  const validationErrors = {};

  form.forEach((field) => {
    if (field.required && !values[field.fieldId]) {
      validationErrors[field.fieldId] = "Field required"; //change to language
    } else if (
      field.validate === "rrc" &&
      values[field.fieldId] &&
      !/^[EPL][0-9]{8}$/i.test(values[field.fieldId])
    ) {
      validationErrors[field.fieldId] = "Invalid Registration Format";
    } else if (field.validation === "cellphone" && values[field.fieldId]) {
      let phoneNumber = values[field.fieldId];
      let countryCode = config[process.env.REACT_APP_SITE_ID].country.iso;
      let number = parsePhoneNumber(phoneNumber, countryCode);

      if (!number.isValid()) {
        validationErrors[field.fieldId] = "Invalid contact number";
      }
    }
  });

  return validationErrors;
};

export const validatePhoneNumber = (value) => {
  try {
    let phoneNumber = value;
    let countryCode = config[process.env.REACT_APP_SITE_ID].country.iso;
    let number = parsePhoneNumber(phoneNumber, countryCode);

    if (!number.isValid()) {
      return false;
    }

    return number;
  } catch (err) {
    return false;
  }
};

export const getFormattedNumber = (phoneNumber, countryCode) => {
  const number = parsePhoneNumber(phoneNumber, countryCode);

  return number.format("E.164");
};

export const calTimeRemaining = (time) =>
  Math.round((time - Date.now()) / 1000);

export const getStoredUserAuth = () => {
  const auth = window.localStorage.getItem("UserPNPAuth");
  if (auth) {
    return JSON.parse(auth);
  }
  return null;
};

export const setStoredStructure = (structure) => {
  window.localStorage.setItem("Election", JSON.stringify(structure));
};

export const getStoredPD = (userStructure) => {
  const structure = window.localStorage.getItem(`Election`);
  if (structure) {
    return JSON.parse(structure);
  }

  if (userStructure) {
    return {
      [userStructure.type.toLowerCase()]: userStructure.code,
    };
  }
  return {};
};

export const getLowestLevel = (structure) => {
  const structure_levels = [
    "national",
    "region",
    "constituency",
    "electoralDivision",
    "pd",
    "ps",
  ];

  return structure_levels.reduce((result, item) => {
    if (structure[item]) {
      result = { [item]: structure[item] };
    }
    return result;
  }, {});
};

const role_types = {
  NATIONAL: "national",
  REGIONAL: "region",
  CONSTITUENCY: "constituency",
  ELECTORAL: "electoraldivision",
  PD: "pd",
};

const getStructure = (role, structures) => {
  let type = role_types[role];

  if (role === "PD") {
    type = "pollingdivision";

    const temp = structures.find((s) => s.type.toLowerCase() === type);

    return {
      ...temp,
      type: "pd",
    };
  }
  return structures.find((s) => s.type.toLowerCase() === type);
};

export const getRole = (roles) => {
  let hasRole = false;
  let structure;

  Object.keys(role_types).forEach((role) => {
    const userRole = roles.find((r) => r.role === role);

    if (userRole && !hasRole) {
      hasRole = true;
      structure = getStructure(role, userRole.structures);
    }
  });

  return structure;
};
