import React from "react";
import { Col, Card } from "antd";

const CardComponent = ({ title, key, children }) => {
  return (
    <Col
      className="gutter-row"
      xs={24}
      sm={24}
      md={24}
      lg={12}
      key={`${title}: ${key}`}
    >
      <Card title={title}>{children}</Card>
    </Col>
  );
};

export default CardComponent;
