import React, { useContext, useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { useMutation } from "react-apollo";
import Alert from "../../components/Alert";
import { gql } from "apollo-boost";
import { Box } from "rebass";

import { authContext } from "./Auth";
import config from "../../common/config";
const LOGIN_USER = gql`
  mutation loginUser(
    $registrationNumber: String!
    $cellphone: String
    $email: String
  ) {
    login(
      registrationNumber: $registrationNumber
      cellphone: $cellphone
      email: $email
    ) {
      token
    }
  }
`;

const LoginForm = (props) => {
  const [loginUser] = useMutation(LOGIN_USER);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setApplicationToken, auth } = useContext(authContext);

  useEffect(() => {
    if (auth) {
      navigate("/search");
    }
  }, [auth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    props.form.validateFields((err, values) => {
      if (!err) {
        loginUser({
          variables: {
            ...values,
          },
        })
          .then(({ data }) => {
            setLoading(false);

            setApplicationToken(data.login.token);

            navigate("/confirm");
          })
          .catch((err) => {
            setLoading(false);

            setError(err.graphQLErrors[0].message);
          });
      }
      if (err) {
        setLoading(false);
        setError(err);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <Row type="flex" justify="center" style={{ minHeight: "100vh" }}>
      <Col style={{ width: "100%", maxWidth: "500px" }}>
        <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
          <div className="app-logo">
            <img
              alt={"logo"}
              src={config[process.env.REACT_APP_SITE_ID].logo}
              className="app-logo"
              style={{
                maxWidth: "200px",
                height: "auto",
              }}
            />
          </div>
          {loading ? (
            <Spin />
          ) : (
            <Form onSubmit={handleSubmit} className="login-form">
              <Form.Item label={config[process.env.REACT_APP_SITE_ID].voterId}>
                {getFieldDecorator("registrationNumber", {
                  rules: [
                    {
                      required: true,
                      message: `Please input your ${config[
                        process.env.REACT_APP_SITE_ID
                      ].voterId.toLowerCase()}`,
                    },
                  ],
                })(
                  <Input
                    placeholder={config[process.env.REACT_APP_SITE_ID].voterId}
                  />
                )}
              </Form.Item>
              <Form.Item label="Email">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your email address",
                    },
                  ],
                })(<Input type="email" />)}
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          )}
          {error && <Alert error message={error} />}
        </Box>
      </Col>
    </Row>
  );
};

const LoginScreen = Form.create({ name: "normal_login" })(LoginForm);

export default LoginScreen;
