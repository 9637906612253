import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { usePosition } from "../../../hooks/usePosition";
//import MapContainer from "./GoogleMap";

const Location = ({ onChange }) => {
  const { latitude, longitude, timestamp, error } = usePosition();

  useEffect(
    () => {
      if (latitude) {
        onChange(`${latitude}|${longitude}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [latitude, longitude]
  );

  if (!latitude) {
    return <div>Trying to fetch location...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Form.Item label={"Latitude"}>
        <Input disabled={true} readOnly={true} value={latitude} />
      </Form.Item>
      <Form.Item label={"Longitude"}>
        <Input disabled={true} readOnly={true} value={longitude} />
      </Form.Item>
      <div>
        <small>Last Updated: {timestamp}</small>
      </div>
    </>
  );
};

export default Location;
