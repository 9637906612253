import * as React from "react";

import { getStoredUserAuth } from "../../common/utils";
export const useAuthHandler = (initialState = null) => {
  const [auth, setAuth] = React.useState(initialState);
  const [application, setApplication] = React.useState(null);

  const setAuthStatus = userAuth => {
    window.localStorage.setItem("UserPNPAuth", JSON.stringify(userAuth));
    setAuth(userAuth);
  };

  const setUnauthStatus = () => {
    window.localStorage.clear();
    setAuth(null);
  };

  const setApplicationToken = token => {
    setApplication(token);
  };

  return {
    auth,
    application,
    setApplicationToken,
    setAuthStatus,
    setUnauthStatus
  };
};

export const authContext = React.createContext({
  auth: null,
  setAuthStatus: () => {},
  setUnauthStatus: () => {}
});

const { Provider } = authContext;

const AuthProvider = ({ children }) => {
  const {
    auth,
    setAuthStatus,
    setUnauthStatus,
    setApplicationToken,
    application
  } = useAuthHandler(getStoredUserAuth());
  return (
    <Provider
      value={{
        auth,
        setAuthStatus,
        setUnauthStatus,
        setApplicationToken,
        application
      }}
    >
      {children}
    </Provider>
  );
};
export default AuthProvider;
