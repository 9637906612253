import React from "react";
import { Card } from "antd";

const DashboardItem = ({ children, title }) => (
  <Card
    title={title}
    bordered={false}
    bodyStyle={{ padding: "0" }}
    style={{
      height: "100%",
      width: "100%",
    }}
  >
    {children}
  </Card>
);

export default DashboardItem;
