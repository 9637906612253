import React, { useState, useEffect } from "react";
import { Table, Col, Spin } from "antd";
import GoogleMap from "../../components/Grid/Location/GoogleMap";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import config from "../../common/config";

const CanvasserMap = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={12} className="gutter-row">
        <Table
          rowSelection={{
            type: "radio",
            onChange,
          }}
          columns={props.columns}
          dataSource={props.data.map((p, i) => ({ ...p, key: i }))}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} className="gutter-row">
        {selectedRowKeys.length ? (
          <CanvasserVoterMap {...props} user={props.data[selectedRowKeys[0]]} />
        ) : null}
      </Col>
    </>
  );
};

const CanvasserVoterMap = ({ structure, user }) => {
  const { loading, data, refetch } = useQuery(GET_USER_GEO_CANVASS, {
    variables: {
      input: structure,
      user: user.id,
    },
  });

  useEffect(
    () => {
      refetch({
        input: structure,
        user: user.id,
      });
    },
    [user, structure, refetch]
  );

  if (loading) return <Spin />;

  return (
    <div style={{ height: "500px" }}>
      <GoogleMap
        defaultZoom={9}
        key={new Date()}
        latitude={config[process.env.REACT_APP_SITE_ID].latitude}
        longitude={config[process.env.REACT_APP_SITE_ID].longitude}
        markers={
          data.canvasserGeoCanvass && data.canvasserGeoCanvass.length
            ? data.canvasserGeoCanvass
                .filter((p) => p.location)
                .map((p) => ({ lng: p.location.lng, lat: p.location.lat }))
            : []
        }
      />
    </div>
  );
};

const GET_USER_GEO_CANVASS = gql`
  query($input: ReportingQueryInput, $user: ID) {
    canvasserGeoCanvass(input: $input, user: $user) {
      registrationNumber
      location {
        lat: latitude
        lng: longitude
      }
    }
  }
`;

export default CanvasserMap;
