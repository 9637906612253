import React from "react";
import { Card, Layout } from "antd";
import { Link } from "@reach/router";

const DashboardScreen = () => {
  return (
    <>
      <Layout.Content style={{ padding: "0 50px" }}>
        <Link to="/admin/users">
          <Card style={{ width: 300, marginTop: 16 }}>
            <Card.Meta title="Users" description="Manage Users" />
          </Card>
        </Link>
      </Layout.Content>
    </>
  );
};

export default DashboardScreen;
