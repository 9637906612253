import React, { useState } from "react";
import GotvContainer from "../Gotv/GotvContainer";
import { Box, Heading } from "rebass";
import { Form, Input, Button, Select, message, Alert } from "antd";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";

import GotvStructures from "./GotvStructures";
const { Option } = Select;

const hours = [
  { value: 1, label: "1 am" },
  { value: 2, label: "2 am" },
  { value: 3, label: "3 am" },
  { value: 4, label: "4 am" },
  { value: 5, label: "5 am" },
  { value: 6, label: "6 am" },
  { value: 7, label: "7 am" },
  { value: 8, label: "8 am" },
  { value: 9, label: "9 am" },
  { value: 10, label: "10 am" },
  { value: 11, label: "11 am" },
  { value: 12, label: "12 am" },
  { value: 13, label: "1 pm" },
  { value: 14, label: "2 pm" },
  { value: 15, label: "3 pm" },
  { value: 16, label: "4 pm" },
  { value: 17, label: "5 pm" },
  { value: 18, label: "6 pm" },
  { value: 19, label: "7 pm" },
  { value: 20, label: "8 pm" },
  { value: 21, label: "9 pm" },
  { value: 22, label: "10 pm" },
  { value: 23, label: "11 pm" },
  { value: 24, label: "12 pm" },
];

const InsideApp = (props) => {
  var d = new Date();
  var n = d.getHours();

  const defaultState = {
    totalVoted: 0,
    totalSupportersVoted: 0,
    hour: n,
    electionCode: parseInt(props.electionCode),
  };

  const [setCount, { loading, data }] = useMutation(SET_COUNT, {
    onCompleted: (data) => {
      const { setElectionCount } = data;
      if (!setElectionCount.length) {
        setValues(defaultState);
        message.success("Submitted successful");
      }
    },
  });

  const [errors, setErrors] = useState(null);

  const [values, setValues] = useState(defaultState);

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const onSubmit = (structure) => {
    if (values.totalSupportersVoted > values.totalVoted) {
      setErrors("Total supporters cannot be greater than total voted.");
    } else if (!values.hour) {
      setErrors("Please select a hour");
    } else {
      setErrors(null);
      setCount({
        variables: {
          input: { ...values, pdCode: structure.pd },
        },
      });
    }
  };

  return (
    <GotvContainer>
      {(structure) => (
        <>
          <GotvStructures structure={structure} />
          <Box mt={[3]} mx="auto" px={[3]}>
            <Heading mb={[3]} size="h4">
              Inside
            </Heading>
            {errors && <Alert message={errors} type="error" />}
            {data && data.setElectionCount.length ? (
              <div>
                {data.setElectionCount.map((message) => (
                  <Box pb={[3]}>
                    <Alert message={message} type="error" />
                  </Box>
                ))}
              </div>
            ) : null}
            <>
              <Form.Item label="Total Voted">
                <Input
                  type="number"
                  onChange={(e) =>
                    onSetValues({ totalVoted: parseInt(e.target.value) })
                  }
                  value={values.totalVoted ? values.totalVoted : null}
                  placeholder={0}
                />
              </Form.Item>
              <Form.Item
                label="Total Supporters Voted"
                style={{ fontWeight: 700 }}
              >
                <Input
                  onChange={(e) =>
                    onSetValues({
                      totalSupportersVoted: parseInt(e.target.value),
                    })
                  }
                  type="number"
                  value={
                    values.totalSupportersVoted
                      ? values.totalSupportersVoted
                      : null
                  }
                  placeholder={0}
                />
              </Form.Item>

              <Form.Item
                label="Total Opposition Votes (Calculated)"
                style={{ fontWeight: 700 }}
              >
                <Input
                  disabled={true}
                  type="number"
                  value={values.totalVoted - values.totalSupportersVoted}
                />
              </Form.Item>

              <Form.Item label="Hour" style={{ fontWeight: 700 }}>
                <Select
                  style={{ width: "100% " }}
                  placeholder="Select hour"
                  onChange={(value) => setValues({ ...values, hour: value })}
                  value={values.hour}
                >
                  {hours.map((hour) => (
                    <Option value={hour.value}>{hour.label}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => onSubmit(structure)}
                >
                  {loading ? "Loading" : "Submit"}
                </Button>
              </Form.Item>
            </>
          </Box>
        </>
      )}
    </GotvContainer>
  );
};

export default InsideApp;

const SET_COUNT = gql`
  mutation submitVoted($input: ElectionCount) {
    setElectionCount(input: $input)
  }
`;
