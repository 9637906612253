import React from "react";
import { Drawer, Button, Menu } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import { PrivateRoleRoute, PrivateStructureRoute, PrivateRoute } from "../App";
import Logout from "../modules/Auth/Logout";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";

export const GET_VIEWER_GOTV = gql`
  {
    viewer {
      gotv {
        code
        type
        role
      }
    }
  }
`;
const SideDrawer = ({ visible, setVisible }) => {
  const showDrawer = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(false);
  };

  const { loading, data } = useQuery(GET_VIEWER_GOTV);

  return (
    <>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={"75%"}
      >
        <MenuUnfoldOutlined
          style={{ fontSize: "32px" }}
          as={Button}
          onClick={showDrawer}
        />

        <PrivateRoute path="/search" as={React.Fragment} key="search">
          <Menu>
            <Menu.Item onClick={() => navigate("/search")}>Canvass</Menu.Item>
          </Menu>
        </PrivateRoute>

        {!loading && data.viewer.gotv ? (
          <Menu>
            <Menu.Item
              onClick={() =>
                navigate(
                  `/gotv/${data.viewer.gotv.type}/${data.viewer.gotv.code}`
                )
              }
              block
              size="large"
              type="primary"
              style={{ marginTop: "1rem" }}
            >
              Ready Reports
            </Menu.Item>
          </Menu>
        ) : null}

        <PrivateRoleRoute
          key={`${new Date()}-admin-${Math.random()}`}
          role={"Admin"}
          as={React.Fragment}
        >
          <Menu>
            <Menu.Item
              onClick={() => navigate("/admin/users")}
              block
              size="large"
              type="primary"
              style={{ marginTop: "1rem" }}
            >
              Admin
            </Menu.Item>
          </Menu>
        </PrivateRoleRoute>

        <PrivateRoleRoute
          key={`${new Date()}-reporting-${Math.random()}`}
          role={"Reporting"}
          as={React.Fragment}
        >
          <PrivateStructureRoute
            key={`${new Date()}-admin`}
            as={React.Fragment}
            render={(structure) => {
              return (
                <Menu>
                  <Menu.Item
                    onClick={() =>
                      navigate(
                        `/reporting/${structure.type.toLowerCase()}/${
                          structure.code
                        }`
                      )
                    }
                    block
                    size="large"
                    type="primary"
                    style={{ marginTop: "1rem" }}
                  >
                    Reporting
                  </Menu.Item>
                </Menu>
              );
            }}
          />
        </PrivateRoleRoute>

        <Menu>
          <Menu.Item
            onClick={() => navigate(`/gotv`)}
            block
            size="large"
            type="primary"
            style={{ marginTop: "1rem" }}
          >
            GOTV Dashboard
          </Menu.Item>
        </Menu>

        <Logout />
      </Drawer>
    </>
  );
};

export default SideDrawer;
