import React, { useState } from "react";
import GotvContainer from "../Gotv/GotvContainer";
import { Box, Heading, Flex } from "rebass";
import { Form, Input, Button, Divider, message } from "antd";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import ResultsContainer from "../Search/ResultsContainer";
import GotvStructures from "./GotvStructures";
import AffiliationModal from "./AffiliationModal";

const OutsideApp = ({ electionCode, ...props }) => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
  });
  const [modal, setModal] = useState({
    visible: false,
    voter: null,
  });
  const [search, setSearch] = useState({});

  const onSearch = () => {
    setSearch({
      ...values,
    });
  };

  const onSetValues = (payload) => {
    setValues({
      ...values,
      ...payload,
    });
  };

  const reset = () => {
    setValues({
      firstName: "",
      lastName: "",
    });
  };
  console.log(modal);
  return (
    <GotvContainer>
      {(structure) => {
        return (
          <>
            {structure && structure.pd ? (
              <GotvStructures structure={structure} />
            ) : null}
            <Box mt={[3]} mx="auto" px={[3]}>
              <Heading mb={[3]} size="h4">
                Outside
              </Heading>
              <>
                <Form.Item label="First Name">
                  <Input
                    placeholder="Search by first name"
                    onChange={(e) => onSetValues({ firstName: e.target.value })}
                    value={values.firstName ? values.firstName : ""}
                  />
                </Form.Item>
                <Form.Item label="Last name" style={{ fontWeight: 700 }}>
                  <Input
                    placeholder="Search by last name"
                    onChange={(e) => onSetValues({ lastName: e.target.value })}
                    value={values.lastName ? values.lastName : ""}
                  />
                </Form.Item>

                <Form.Item label="Voter Number" style={{ fontWeight: 700 }}>
                  <Input
                    placeholder="Search by voter number"
                    onChange={(e) =>
                      onSetValues({ registrationNumber: e.target.value })
                    }
                    value={
                      values.registrationNumber ? values.registrationNumber : ""
                    }
                  />
                </Form.Item>

                <Flex my={[2]}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={() => onSearch(structure)}
                  >
                    Search
                  </Button>
                  <Box px={[2]}>
                    <Button type="text" onClick={reset}>
                      Clear input
                    </Button>
                  </Box>
                </Flex>
              </>
              <Divider />

              <ResultsContainer
                election={parseInt(electionCode)}
                type={"voted"}
                search={{
                  ...structure,
                  ...search,
                }}
                renderAction={(item) => (
                  <>
                    <ActionBox
                      item={item}
                      electionCode={electionCode}
                      pd={structure.pd}
                      onModalOpen={() => {
                        setModal({
                          visible: true,
                          voter: item,
                        });
                      }}
                    />
                  </>
                )}
              />
            </Box>
            <AffiliationModal
              onClose={() => {
                setModal({
                  visible: false,
                  voter: null,
                });
              }}
              voter={modal.voter}
              visible={modal.visible}
            />
          </>
        );
      }}
    </GotvContainer>
  );
};

const ActionBox = ({ item, electionCode, pd, onModalOpen }) => {
  const [setVoted, { loading: votedLoader }] = useMutation(SET_VOTED);

  const handleClick = async (payload) => {
    await setVoted({
      variables: {
        election: parseInt(electionCode),
        input: {
          voted: payload,
          electionCode: parseInt(electionCode),
          registrationNumber: item.registrationNumber,
        },
      },
    });

    message.success("Voter updated");
  };

  const [vote] = item.voted;

  return (
    <Flex>
      {vote && vote.voted ? (
        <>
          <Button
            disabled={votedLoader}
            onClick={() => handleClick(false)}
            type="primary"
          >
            Unmark
          </Button>
          <Box>
            <Button
              type="link"
              onClick={() => onModalOpen(item.registrationNumber)}
            >
              Update Affiliation
            </Button>
          </Box>
        </>
      ) : (
        <Button
          disabled={votedLoader}
          onClick={() => handleClick(true)}
          type="primary"
        >
          Mark as voted
        </Button>
      )}
    </Flex>
  );
};

export default OutsideApp;

const SET_VOTED = gql`
  mutation submitVoted($input: VotedInput, $election: Int) {
    setVoted(input: $input) {
      id
      firstName
      middleName
      lastName
      registrationNumber
      voted(where: { electionCode: $election }) {
        id
        voted
      }
      lastAffiliation
      pollingDivision {
        constituency {
          name
        }
        pdCode
      }
    }
  }
`;
