import React from "react";
import { Box, Flex, Text } from "rebass";
import { Spin, Row, Col } from "antd";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";

const GotvStructures = ({ structure }) => {
  const { loading, data, error } = useQuery(GET_POLLS, {
    variables: {
      input: {
        pd: structure.pd,
      },
    },
  });

  if (loading || !data) {
    return (
      <Flex justifyContent="center" alignItems="center" px={[3]}>
        <Spin />
      </Flex>
    );
  }

  if (error) {
    console.log("error:", error);
    return null;
  }

  const { polls } = data;

  const pollingData = polls.reduce((result, item) => {
    result.electoralDivision = "";
    result.constituency = item.constituency.name;
    result.pdName = item.pdName;
    result.stream = structure.ps
      ? item.streams.find((stream) => stream.id === structure.ps)
      : null;
    return result;
  }, {});

  if (!structure) {
    return null;
  }

  if (!structure.pd) {
    return null;
  }

  return (
    <Box backgroundColor="#D3D3D3" m={[3]}>
      <Row gutter={[16, 16]}>
        {pollingData.stream ? (
          <Col
            xs={{ span: 24, offset: 1 }}
            md={{ span: 24, offset: 1 }}
            lg={{ span: 6, offset: 2 }}
            xl={{ span: 6, offset: 2 }}
          >
            <Box sx={{ maxWidth: "100%" }}>
              <Text fontWeight="bold">{`PS: ${pollingData.stream.name} (${
                pollingData.stream.surnames[0]
              }-${
                pollingData.stream.surnames[
                  pollingData.stream.surnames.length - 1
                ]
              })`}</Text>
            </Box>
          </Col>
        ) : null}
        <Col
          xs={{ span: 24, offset: 1 }}
          md={{ span: 24, offset: 1 }}
          lg={{ span: 6, offset: 2 }}
          xl={{ span: 6, offset: 2 }}
        >
          <Box sx={{ maxWidth: "100%" }}>
            <Text fontWeight="bold">{`PD: ${
              pollingData.pdName || pollingData.pdCode
            }`}</Text>
          </Box>
        </Col>

        {pollingData.constituency && (
          <Col
            xs={{ span: 24, offset: 1 }}
            md={{ span: 24, offset: 1 }}
            lg={{ span: 6, offset: 2 }}
            xl={{ span: 6, offset: 2 }}
          >
            <Box sx={{ maxWidth: "100%" }}>
              <Text fontWeight="bold">{`Constituency: ${pollingData.constituency}`}</Text>
            </Box>
          </Col>
        )}
      </Row>
    </Box>
  );
};

export default GotvStructures;

const GET_POLLS = gql`
  query getPolls($input: PollingDivisionInput) {
    polls(input: $input) {
      pdName
      pdCode
      streams {
        id
        name
        surnames
      }
      electoralDivision {
        name
      }
      constituency {
        name
      }
    }
  }
`;
