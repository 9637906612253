import React, { useContext } from "react";
import { Box, Heading, Flex } from "rebass";
import { Spin, Button, Typography, Divider, Alert } from "antd";

import GotvContainer from "../../Gotv/GotvContainer";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { authContext } from "../../Auth/Auth";
import { LabelContainer } from "../../Voter/VoterProfileScreen";

const { Text } = Typography;

const CALL_STATUSES = {
  busy: "BUSY",
  pending: "PENDING",
  skipped: "SKIPPED",
  voted: "VOTED",
  votingLater: "VOTING_LATER",
  wont_vote: "WONT_VOTE",
};

const CallingApp = ({ electionCode }) => {
  const {
    auth: {
      user: { registrationNumber },
    },
  } = useContext(authContext);

  const { loading, data, refetch } = useQuery(GET_VOTER_TO_CALL, {
    variables: {
      electionCode: parseInt(electionCode),
      assignedUser: registrationNumber,
    },
  });

  const [updateVoterCall, { loading: updateCallLoader }] = useMutation(
    UPDATE_STATUS
  );

  if (loading || !data || updateCallLoader) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spin />
      </Flex>
    );
  }

  const { getVoterToCall } = data;

  return (
    <GotvContainer>
      {() => (
        <>
          {!getVoterToCall.mobile ? (
            <Flex justifyContent="center" alignItems="center" m={[3]}>
              <Alert message="No voter found to call" type="warning" />
            </Flex>
          ) : (
            <>
              <Box mt={[3]} mx="auto" px={[3]}>
                <Heading mb={[3]} size="h4">
                  Calling Voter
                </Heading>

                {getVoterToCall.firstName && (
                  <LabelContainer>
                    <div>
                      <Text fontWeight="bold">First name</Text>
                    </div>
                    <div>
                      <Text color="#777777">{getVoterToCall.firstName}</Text>
                    </div>
                  </LabelContainer>
                )}
                {getVoterToCall.lastName && (
                  <LabelContainer>
                    <div>
                      <Text fontWeight="bold">Last name</Text>
                    </div>
                    <div>
                      <Text color="#777777">{getVoterToCall.lastName}</Text>
                    </div>
                  </LabelContainer>
                )}
                {getVoterToCall.registrationNumber && (
                  <LabelContainer>
                    <div>
                      <Text fontWeight="bold">Registration number</Text>
                    </div>
                    <div>
                      <Text color="#777777">
                        {getVoterToCall.registrationNumber}
                      </Text>
                    </div>
                  </LabelContainer>
                )}
                {getVoterToCall.mobile && (
                  <LabelContainer>
                    <div>
                      <Text fontWeight="bold">Telephone</Text>
                    </div>
                    <div>
                      <Text color="#777777">{getVoterToCall.mobile}</Text>
                    </div>
                  </LabelContainer>
                )}
                {getVoterToCall.mobile && (
                  <LabelContainer>
                    <div>
                      <Text fontWeight="bold">Status</Text>
                    </div>
                    <div>
                      <Text color="#777777">{getVoterToCall.status}</Text>
                    </div>
                  </LabelContainer>
                )}
                {getVoterToCall.tags.length &&
                  getVoterToCall.tags.map((tag) => {
                    return (
                      <LabelContainer>
                        <Text color="#777777">{tag.name}</Text>
                      </LabelContainer>
                    );
                  })}
              </Box>
              <Box>
                <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                  <Button
                    block
                    size="large"
                    style={{ backgroundColor: "#1b8b3f", color: "white" }}
                    onClick={() => {
                      updateVoterCall({
                        variables: {
                          status: CALL_STATUSES.voted,
                          registrationNumber: getVoterToCall.registrationNumber,
                        },
                      });
                      refetch();
                    }}
                  >
                    Voted
                  </Button>
                </Box>
                <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                  <Button
                    block
                    size="large"
                    style={{ backgroundColor: "#f58945", color: "white" }}
                    onClick={() => {
                      updateVoterCall({
                        variables: {
                          status: CALL_STATUSES.votingLater,
                          registrationNumber: getVoterToCall.registrationNumber,
                        },
                      });
                      refetch();
                    }}
                  >
                    Voting Later
                  </Button>
                </Box>
                <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
                  <Button
                    block
                    size="large"
                    style={{ backgroundColor: "#e73232", color: "white" }}
                    onClick={() => {
                      updateVoterCall({
                        variables: {
                          status: CALL_STATUSES.wont_vote,
                          registrationNumber: getVoterToCall.registrationNumber,
                        },
                      });
                      refetch();
                    }}
                  >
                    Won't Vote
                  </Button>
                </Box>
                <Divider />

                <Flex>
                  <Box width={1 / 2} p={3}>
                    <Button
                      block
                      type="primary"
                      as="a"
                      href={`tel:${getVoterToCall.mobile}`}
                    >
                      Dial
                    </Button>
                  </Box>

                  <Box width={1 / 2} p={3}>
                    <Button
                      type="text"
                      style={{ width: "100%" }}
                      onClick={() => {
                        updateVoterCall({
                          variables: {
                            status: CALL_STATUSES.skipped,
                            registrationNumber:
                              getVoterToCall.registrationNumber,
                          },
                        });
                        refetch();
                      }}
                    >
                      Skip
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </>
          )}
        </>
      )}
    </GotvContainer>
  );
};

export default CallingApp;

const GET_VOTER_TO_CALL = gql`
  query getVoter($electionCode: Int, $assignedUser: String) {
    getVoterToCall(electionCode: $electionCode, assignedUser: $assignedUser) {
      tags {
        name
        code
      }
      registrationNumber
      firstName
      lastName
      mobile
      status
      assignedUser
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation updateVoterCall(
    $status: VoterCallStatus
    $registrationNumber: String
  ) {
    updateVoterCallStatus(
      status: $status
      registrationNumber: $registrationNumber
    )
  }
`;
