import React from "react";
import { Spin, List, Icon } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";

const GET_VOTERS_QUERY = gql`
  query queryVoters($input: VoterSearchInput, $after: String, $first: Int) {
    voters(input: $input, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          firstName
          middleName
          lastName
          registrationNumber
          lastAffiliation
          contact(where: { type: "mobile" }, first: 1) {
            value
          }
          pollingDivision {
            constituency {
              name
            }
            pdCode
          }
        }
      }
    }
  }
`;

const GET_VOTED_QUERY = gql`
  query queryVoters(
    $input: VoterSearchInput
    $after: String
    $first: Int
    $election: Int
  ) {
    voters(input: $input, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          firstName
          middleName
          lastName
          registrationNumber
          voted(where: { electionCode: $election }) {
            id
            voted
          }
          lastAffiliation
          pollingDivision {
            constituency {
              name
            }
            pdCode
          }
        }
      }
    }
  }
`;
const queries = {
  voted: {
    gql: GET_VOTED_QUERY,
    variables: ["election"],
  },
  voters: {
    gql: GET_VOTERS_QUERY,
    variables: [],
  },
};

const ResultsContainer = ({
  search,
  type = "voters",
  onItemClick,
  icon,
  useWindow = true,
  renderAction = null,
  ...props
}) => {
  const query = queries[type];

  const { data, loading, fetchMore } = useQuery(query.gql, {
    variables: {
      input: {
        firstName: search.firstName,
        lastName: search.lastName,
        streetName: search.streetName,
        registrationNumber: search.registrationNumber,
        region: search.region ? parseInt(search.region) : null,
        constituency: search.constituency ? search.constituency : null,
        electoralDivision: search.electoralDivision
          ? parseInt(search.electoralDivision)
          : null,
        pdCode: search.pd ? search.pd : null,
        ps: search.ps ? search.ps : null,
      },
      first: 20,
      ...query.variables.reduce((result, item) => {
        if (props[item]) {
          result[item] = props[item];
        }

        return result;
      }, {}),
    },
    fetchPolicy: "cache-and-network",
  });

  if (!data || !data.voters) return <Spin />;

  const { voters = [] } = data;

  const onFetchMore = () => {
    fetchMore({
      variables: {
        input: {
          firstName: search.firstName,
          lastName: search.lastName,
          streetName: search.streetName,
          registrationNumber: search.registrationNumber,
          region: search.region ? parseInt(search.region) : null,
          constituency: search.constituency ? search.constituency : null,
          electoralDivision: search.electoralDivision
            ? parseInt(search.electoralDivision)
            : null,
          pdCode: search.pd ? parseInt(search.pd) : null,
          ps: search.ps ? search.ps : null,
        },
        after: voters.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        return {
          voters: {
            __typename: "Voters",
            pageInfo: fetchMoreResult.voters.pageInfo,
            edges: [
              ...previousResult.voters.edges,
              ...fetchMoreResult.voters.edges,
            ],
          },
        };
      },
    });
  };

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={onFetchMore}
      hasMore={!loading && voters.pageInfo.hasNextPage}
      useWindow={useWindow}
    >
      <List
        dataSource={voters.edges}
        locale={{
          emptyText: "No Voters Found",
        }}
        renderItem={({ node: item }) => (
          <List.Item
            className="results-list-item"
            key={item.registrationNumber}
            onClick={renderAction ? null : () => onItemClick(item)}
            extra={<Icon type={icon} />}
            actions={renderAction ? [renderAction(item)] : []}
          >
            <List.Item.Meta
              title={`${item.firstName} ${item.middleName || ""} ${
                item.lastName
              }`}
              description={[
                item.registrationNumber,
                item.lastAffiliation || "Unknown",
                item.pollingDivision.constituency?.name ?? "",
              ].join(" | ")}
            />
          </List.Item>
        )}
      >
        {loading && (
          <div className="demo-loading-container">
            <Spin />
          </div>
        )}
      </List>
    </InfiniteScroll>
  );
};

export default ResultsContainer;
