import React from "react";
import ReactDOM from "react-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { ThemeProvider } from "emotion-theming";
import theme from "@rebass/preset";

import App from "./App";

const token = process.env.REACT_APP_PRIMSA;
// Pass your prisma endpoint to uri
const client = new ApolloClient({
  uri: process.env.REACT_APP_API,
  request: operation => {
    const auth = JSON.parse(localStorage.getItem("UserPNPAuth") || null);
    operation.setContext({
      headers: {
        "x-access-token": auth && auth.token ? `Bearer ${auth.token}` : "",
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
