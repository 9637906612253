import React, { useContext, Children, cloneElement } from "react";
import { Router } from "@reach/router";
import LoginScreen from "./modules/Auth/LoginScreen";
import "./App.css";
import SearchScreen from "./modules/Search/SearchScreen";
import ResultsProvider from "./modules/Search/ResultsProvider";
import ResultScreen from "./modules/Search/ResultsScreen";
import VoterProfileScreen from "./modules/Voter/VoterProfileScreen";
import CanvassScreen from "./modules/Canvass/CanvassScreen";
import ContactScreen from "./modules/Canvass/ContactScreen";
import CanvassSuccessScreen from "./modules/Canvass/CanvassSuccess";
import AuthContextProvider, { authContext } from "./modules/Auth/Auth";
import ConfirmScreen from "./modules/Auth/ConfirmScreen";
import AdminScreen from "./modules/Admin/AdminScreen";
import DashboardScreen from "./modules/Admin/DashboardScreen";
import UsersScreen from "./modules/Admin/UsersScreen";
import { Box } from "rebass";
import ReportingStructure from "./modules/Reporting/ReportingStructure";
import Voters from "./modules/Voters/Voters";
import GotvDashboard from "./modules/Gotv/GotvDashboard";
import InsideApp from "./modules/Gotv/InsideApp";
import OutsideApp from "./modules/Gotv/OutsideApp";
import CallingContainer from "./modules/Gotv/CallingApp/CallingContainer";
import CallingDial from "./modules/Gotv/CallingApp/CallingDial";
import config from "./common/config";
import SearchContainer from "./modules/Gotv/SearchContainer";
import OfflineContainer from "./modules/Offline/Search";
import Turnout from "./modules/Gotv/Turnout";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import WebSocketTransport from "@cubejs-client/ws-transport";
import CaptureScreen from "./modules/Canvass/CaptureScreen";
import CanvassAffScreen from "./modules/Canvass/CanvassAffScreen";

const API_URL = process.env.REACT_APP_WS || "https://ulp-api.mamela.io/ws";
const CUBEJS_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1Ijp7fSwiaWF0IjoxNjA0NDYzMjkyfQ.e4uU67f6P8TUdwS8mDgCKtxp-6ABtQIf7OSwo0_OCFA";
const cubejsApi = cubejs({
  transport: new WebSocketTransport({
    authorization: CUBEJS_TOKEN,
    apiUrl: API_URL.replace("https", "wss"),
  }),
});

export const PrivateRoute = (props) => {
  const { auth } = useContext(authContext);
  let { as: Comp, ...restProps } = props;
  return auth ? <Comp {...restProps} /> : <LoginScreen />;
};

export const PrivateComponent = ({ children, ...props }) => {
  const { auth } = useContext(authContext);

  if (!auth) return <LoginScreen />;
  const {
    user: { roles = [] },
  } = auth;

  if (roles.some((r) => props.roles.includes(r.role))) {
    const childrenWithProps = Children.map(children, (child) => {
      return cloneElement(child, props);
    });

    return <>{childrenWithProps}</>;
  }

  return null;
};

export const PrivateRoleRoute = (props) => {
  const { auth } = useContext(authContext);

  if (!auth) return <LoginScreen />;
  const {
    user: { roles = [] },
  } = auth;

  if (
    roles.some((r) => {
      const exclusions = r.exclusions ? r.exclusions.split("|") : [];
      return !exclusions.includes(props.role);
    })
  ) {
    let { as: Comp = React.Fragment, children, ...restProps } = props;
    return <Comp {...restProps}>{children}</Comp>;
  }

  return <div />;
};

export const PrivateStructureRoute = (props) => {
  const { auth } = useContext(authContext);

  if (!auth) return <LoginScreen />;

  const {
    user: { roles = [] },
  } = auth;

  const role_types = {
    NATIONAL: "national",
    REGIONAL: "region",
    CONSTITUENCY: "constituency",
    ELECTORAL: "electoraldivision",
    PD: "pd",
  };

  const getStructure = (role, structures) => {
    let type = role_types[role];

    if (role === "PD") {
      type = "pollingdivision";

      const temp = structures.find((s) => s.type.toLowerCase() === type);

      return {
        ...temp,
        type: "pd",
      };
    }
    return structures.find((s) => s.type.toLowerCase() === type);
  };

  let hasRole = false;
  let structure;
  Object.keys(role_types).forEach((role) => {
    const userRole = roles.find((r) => r.role === role);

    if (userRole && !hasRole) {
      hasRole = true;
      structure = getStructure(role, userRole.structures);
    }
  });

  if (!structure) return null;

  return props.render ? props.render(structure) : null;
};

export const useStructure = () => {
  const { auth } = useContext(authContext);

  if (!auth) return <LoginScreen />;

  const {
    user: { roles = [] },
  } = auth;

  const role_types = {
    NATIONAL: "national",
    REGIONAL: "region",
    CONSTITUENCY: "constituency",
    ELECTORAL: "electoraldivision",
    PD: "pd",
  };

  const getStructure = (role, structures) => {
    let type = role_types[role];

    if (role === "PD") {
      type = "pollingdivision";

      const temp = structures.find((s) => s.type.toLowerCase() === type);

      return {
        ...temp,
        type: "pd",
      };
    }
    return structures.find((s) => s.type.toLowerCase() === type);
  };

  let hasRole = false;
  let structure;
  Object.keys(role_types).forEach((role) => {
    const userRole = roles.find((r) => r.role === role);

    if (userRole && !hasRole) {
      hasRole = true;
      structure = getStructure(role, userRole.structures);
    }
  });

  return structure;
};

const App = () => {
  return (
    <AuthContextProvider>
      <ResultsProvider>
        <CubeProvider cubejsApi={cubejsApi}>
          <Router>
            <LoginScreen path="/" />
            <LoginScreen path="/login" />
            <ConfirmScreen path="/confirm" />
            <PrivateRoute as={SearchScreen} path="/search" />
            <PrivateRoute as={ResultScreen} path="/results" />

            <PrivateRoute
              as={VoterProfileScreen}
              path="/voter/:registrationNumber"
            />
            <PrivateRoute
              as={CanvassAffScreen}
              path="/voter/:registrationNumber/code"
            />
            <PrivateRoute
              as={CanvassScreen}
              path="/voter/:registrationNumber/canvass"
            />
            <PrivateRoute
              as={ContactScreen}
              path="/voter/:registrationNumber/contact"
            />
            <PrivateRoute
              as={CaptureScreen}
              path="/voter/:registrationNumber/capture"
            />
            <PrivateRoute
              as={CanvassSuccessScreen}
              path="/voter/:registrationNumber/success"
            />
            <PrivateRoleRoute
              role={"Reporting"}
              as={ReportingStructure}
              path="/reporting/:structure/:code"
            />
            <PrivateRoleRoute
              role={"Admin"}
              as={ReportingStructure}
              path="/reporting/:structure/:code"
            />
            <PrivateRoleRoute
              role={"Admin"}
              as={Voters}
              path="/voters/:structure/:code"
            />
            <PrivateRoleRoute
              role={"Admin"}
              as={OfflineContainer}
              path="/offline/:structure/:code"
            />
            <PrivateRoleRoute
              role={"GOTV"}
              as={SearchContainer}
              path="/gotv/:structure/:code"
            />
            <PrivateRoute as={GotvDashboard} path="/gotv" />
            <PrivateRoute
              role={"Admin"}
              as={InsideApp}
              path="/gotv/inside/:electionCode"
            />
            <PrivateRoute as={OutsideApp} path="/gotv/outside/:electionCode" />
            <PrivateRoute
              as={CallingContainer}
              path="/gotv/calling/:electionCode"
            />
            <PrivateRoute
              as={CallingDial}
              path="/gotv/calling/:electionCode/dial"
            />

            <PrivateRoute as={Turnout} path="/gotv/turnout/:electionCode" />

            <PrivateRoleRoute role={"Admin"} path="admin" as={AdminScreen}>
              <DashboardScreen path="/" />
              <UsersScreen path="users" />
            </PrivateRoleRoute>
          </Router>
          <Box py={2} style={{ textAlign: "center" }}>
            <small>
              {config[process.env.REACT_APP_SITE_ID].copyright} ALL RIGHTS
              RESERVED. &copy; 2021. {process.env.REACT_APP_VERSION}
            </small>
          </Box>
        </CubeProvider>
      </ResultsProvider>
    </AuthContextProvider>
  );
};

export default App;
