import React, { useContext, useState } from "react";
import { navigate, Redirect } from "@reach/router";
import { Form, Input, Button, Row, Col, Spin } from "antd";
import { useMutation } from "react-apollo";

import { gql } from "apollo-boost";
import { Box, Text } from "rebass";

import { authContext } from "./Auth";
import Alert from "../../components/Alert";
import config from "../../common/config";

const CONFIRM_USER = gql`
  mutation confirmUser($data: ConfirmInput!) {
    confirm(data: $data) {
      token
      user {
        registrationNumber
        roles {
          role
          exclusions
          structures {
            type
            code
          }
        }
      }
    }
  }
`;

const ConfirmForm = (props) => {
  const [confirmUser] = useMutation(CONFIRM_USER);
  const [loading, setLoading] = useState(false);
  const { setAuthStatus, application } = useContext(authContext);
  const [error, setError] = useState(null);

  if (!application) {
    return <Redirect to="/login" />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFields((err, values) => {
      if (!err) {
        confirmUser({
          variables: {
            data: {
              application,
              token: values.token,
            },
          },
        })
          .then(({ data }) => {
            setLoading(true);
            setAuthStatus(data.confirm);
            navigate("/search");
          })
          .catch((err) => {
            setLoading(false);
            setError(err.graphQLErrors[0].message);
          });
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <Row type="flex" justify="center" style={{ minHeight: "100vh" }}>
      <Col style={{ width: "100%", maxWidth: "500px" }}>
        <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
          <div className="app-logo">
            <img
              alt={"logo"}
              src={config[process.env.REACT_APP_SITE_ID].logo}
              className="app-logo"
              style={{
                maxWidth: "200px",
                height: "auto",
              }}
            />
          </div>
          {loading ? (
            <Spin />
          ) : (
            <Form onSubmit={handleSubmit} className="login-form">
              <Box bg="#eee" p={[2]} mb={[2]}>
                <Text fontWeight="bold" textAlign="center">
                  Please check your inbox for your one time pin. Be sure to
                  check your spam folder.
                </Text>
              </Box>
              <Form.Item label="One Time Pin (OTP)">
                {getFieldDecorator("token", {
                  rules: [
                    {
                      required: true,
                      message: "Please your one time pin received via email",
                    },
                  ],
                })(<Input placeholder="OTP" />)}
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          )}
          {error && <Alert error message={error} />}
        </Box>
      </Col>
    </Row>
  );
};

const ConfirmScreen = Form.create({ name: "normal_login" })(ConfirmForm);

export default ConfirmScreen;
