import React from "react";
import { Table } from "antd";

const TableTags = (props) => {
  return (
    <Table
      className="table-tags"
      dataSource={props.data}
      columns={props.columns}
      pagination={{ pageSize: 10 }}
    />
  );
};

export default TableTags;
