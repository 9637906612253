/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Spin } from "antd";
import "mapbox-gl/dist/mapbox-gl.css";
import config from "../../../common/config";

const styles = {
  width: "100vw",
  height: "calc(100vh - 80px)",
  position: "absolute",
};

const TagMap = ({ query, ...props }) => {
  const { loading, data, refetch } = useQuery(GET_TAG_LOCATIONS, {
    variables: {
      input: query,
    },
  });

  useEffect(() => {
    refetch({
      input: query,
    });
  }, [query, refetch]);

  if (loading) return <Spin />;

  return (
    <>
      {data && data.getCanvassLocationByTag && (
        <MapboxGLMap data={data.getCanvassLocationByTag} />
      )}
    </>
  );
};

const MapboxGLMap = ({ data }) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);

  useEffect(
    () => {
      mapboxgl.accessToken =
        "pk.eyJ1IjoicGx1c3BsdXNtaW51c2EiLCJhIjoiY2t2cWlidzM2OGZqbTMxcXdqbmJhMndrNyJ9.IJ8S1XF5c7MVoiUYyNtT0w";
      const initializeMap = ({ setMap, mapContainer }) => {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
          center: [
            config[process.env.REACT_APP_SITE_ID].longitude,
            config[process.env.REACT_APP_SITE_ID].latitude,
          ],
          zoom: 8,
        });

        map.on("load", () => {
          setMap(map);
          //structureLayer(map);
          clusterLayer(map, data);
          map.resize();
        });
      };

      if (!map) initializeMap({ setMap, mapContainer });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map]
  );

  return (
    <div style={{ height: "100vh" }}>
      <div ref={(el) => (mapContainer.current = el)} style={styles} />
    </div>
  );
};

const clusterLayer = (map, data) => {
  console.log(data);
  map.addSource("structures", {
    type: "geojson",
    data: data, // Point to GeoJSON data
    cluster: true,
    clusterMaxZoom: 14, // Max zoom to cluster points on
    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
  });

  map.addLayer({
    id: "clusters",
    type: "circle",
    source: "structures",
    filter: ["has", "point_count"],
    paint: {
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#51bbd6",
        100,
        "#f1f075",
        750,
        "#f28cb1",
      ],
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
    },
  });

  map.addLayer({
    id: "cluster-count",
    type: "symbol",
    source: "structures",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  });

  map.addLayer({
    id: "unclustered-point",
    type: "circle",
    source: "structures",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color": "red",
      "circle-radius": 6,
      "circle-stroke-width": 1,
      "circle-stroke-color": "#fff",
    },
  });

  map.on("click", "clusters", function (e) {
    var features = map.queryRenderedFeatures(e.point, {
      layers: ["clusters"],
    });
    var clusterId = features[0].properties.cluster_id;
    map
      .getSource("structures")
      .getClusterExpansionZoom(clusterId, function (err, zoom) {
        if (err) return;

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        });
      });
  });

  map.on("mouseenter", "clusters", function () {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "clusters", function () {
    map.getCanvas().style.cursor = "";
  });
};

const structureLayer = (map) => {
  map.addSource("plusplusminusa.3jgn3bqx", {
    type: "vector",
    url: "mapbox://plusplusminusa.3jgn3bqx",
  });
  map.addLayer({
    id: "states-layer",
    type: "fill", //jamaica_electoral_divisions-5rtbuc
    source: "plusplusminusa.3jgn3bqx",
    "source-layer": "jamaica_electoral_divisions-5rtbuc",

    paint: {
      "fill-color": "rgba(200, 100, 240, 0.4)",
    },
  });

  map.addLayer({
    id: "states-boundaries",
    type: "line", //jamaica_electoral_divisions-5rtbuc
    source: "plusplusminusa.3jgn3bqx",
    "source-layer": "jamaica_electoral_divisions-5rtbuc",

    paint: {
      "line-color": "rgba(0, 0, 0, 1)",
      "line-width": 2,
    },
  });

  map.on("click", "states-layer", function (e) {
    console.log(e.features);
    new mapboxgl.Popup()
      .setLngLat(e.lngLat)
      .setHTML(e.features[0].properties.CONST_NAME)
      .addTo(map);
  });
};

const GET_TAG_LOCATIONS = gql`
  query ($input: ReportingQueryInput) {
    getCanvassLocationByTag(input: $input) {
      type
      features {
        type
        properties {
          type
        }
        geometry {
          type
          coordinates
        }
      }
    }
  }
`;

export default TagMap;
