import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import AdminScreen from "../Admin/AdminScreen";
import { getStoredPD, setStoredStructure } from "../../common/utils";
import { GET_SEARCH_OPTIONS, StructuresComponent } from "../Admin/UsersScreen";
import { useQuery } from "react-apollo";
import { Flex } from "rebass";
import { navigate } from "@reach/router";
import { useStructure } from "../../App";

const GotvContainer = (props) => {
  const userStructure = useStructure();

  const { data: user, loading: viewerLoader } = useQuery(GET_SEARCH_OPTIONS);

  const [structure, setStructure] = useState(getStoredPD(userStructure));

  useEffect(
    () => {
      setStoredStructure(structure); // set localstorage //provide reset
    },
    [structure]
  );

  const onSetStructure = (payload) => {
    setStructure({
      ...structure,
      ...payload,
    });
  };

  const reset = () => {
    setStructure({ [userStructure.type.toLowerCase()]: userStructure.code });
    setStoredStructure({});
  };

  if (viewerLoader) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <AdminScreen title="Election" onBack={() => navigate("/gotv")}>
      <StructuresComponent
        onSelect={onSetStructure}
        value={structure}
        defaultRole={"CANVASSER"}
        showDefault={false}
        structures={user.viewer.structures}
        exclude={["RegionComponent", "ElectoralComponent"]}
        components={{
          constituency: { code: "name" },
          pd: { code: "pdName" },
          ps: { code: "name" },
        }}
      />

      {props.children(structure, reset)}
    </AdminScreen>
  );
};

export default GotvContainer;
