import React, { useContext, useEffect } from "react";
import { navigate } from "@reach/router";

import { Spin, Button, Card, Progress } from "antd";
import { useApolloClient } from "react-apollo";
import { Flex, Box, Text } from "rebass";

import { ResultsContext } from "../Search/ResultsProvider";
import { GET_VOTER } from "../Voter/VoterProfileScreen";

import AdminScreen from "../Admin/AdminScreen";

const CanvassSuccessScreen = (props) => {
  const { setVoter, voter } = useContext(ResultsContext);
  const client = useApolloClient();
  useEffect(() => {
    if (!voter) {
      client
        .query({
          query: GET_VOTER,
          variables: { registrationNumber: props.registrationNumber },
        })
        .then(({ data }) => {
          setVoter(data.voter);
        })
        .catch((err) => console.error(err));
    }

    return () => setVoter(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!voter) return <Spin />;

  return (
    <AdminScreen onBack={() => navigate("/search")} title="Successfully Saved">
      <Card>
        <Card.Meta
          title={`${voter.firstName} ${voter.middleName} ${voter.lastName}`}
          description={`${voter.registrationNumber} | ${
            voter.pollingDivision.constituency?.name ?? "-"
          }`}
        />
      </Card>

      <Box
        mt={[3]}
        mx="auto"
        px={[3]}
        sx={{ maxWidth: "100%", textAlign: "center" }}
      >
        <Progress
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#ff7000",
          }}
          type="circle"
          percent={100}
        />
        <Text mt={[2]}>Canvass Complete</Text>
        <Flex py={[4]}>
          <Button
            style={{ marginRight: "10px" }}
            block
            type="primary"
            size="large"
            onClick={() => navigate("/search")}
          >
            New Canvass
          </Button>
        </Flex>
      </Box>
    </AdminScreen>
  );
};

export default CanvassSuccessScreen;
