import React, { useState } from "react";
import { PageHeader, Button } from "antd";
import { Link } from "@reach/router";
import config from "../../common/config";
import SideDrawer from "../../components/SideDrawer";
import { MenuFoldOutlined } from "@ant-design/icons";

const AdminScreen = (props) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };

  const { onBack = null, to = "/search" } = props;
  return (
    <>
      <SideDrawer visible={visible} setVisible={setVisible} />
      <PageHeader
        style={{
          border: "1px solid rgb(235, 237, 240)",
        }}
        title={
          !onBack ? (
            <Link to={to}>
              <span>
                <img
                  alt="logo"
                  style={{ maxWidth: "30px", marginRight: "10px" }}
                  src={config[process.env.REACT_APP_SITE_ID].logo}
                />
                {props.title}
              </span>
            </Link>
          ) : null
        }
        extra={[
          <MenuFoldOutlined
            style={{ fontSize: "32px" }}
            as={Button}
            onClick={showDrawer}
          />,
        ]}
        onBack={onBack}
      />
      {props.children}
    </>
  );
};

export default AdminScreen;
