import React, { useEffect, useState } from "react";
import { Table, Form, Select, Input, Layout, Alert, Button } from "antd";

import { Link } from "@reach/router";
import queryString from "query-string";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "react-apollo";
import { StructuresComponent, GET_SEARCH_OPTIONS } from "../Admin/UsersScreen";
import { STRUCTURE_MAP } from "../Reporting/ReportingStructure";
import { Flex, Box } from "rebass";

import config from "../../common/config";
import AddGotvRole from "./AddGotvRole";
import { getLowestLevel, getStoredUserAuth } from "../../common/utils";
import saveFile from "save-as-file";
import AdminScreen from "../Admin/AdminScreen";

const { Content } = Layout;

const STRUCTURE_LEVELS = {
  PollingDivision: {
    level: 2,
    requires: ["role", "pd|ps", "contact"],
  },
  cluster: {},
  ElectoralDivision: {
    level: 3,
    requires: ["role", "electoralDivision", "contact"],
  },
  Constituency: {
    level: 4,
    requires: ["role", "constituency", "contact"],
  },
  Region: {
    level: 5,
    requires: ["role", "region", "contact"],
  },
  National: {
    level: 7,
    requires: ["role", "national", "contact"],
  },
};

const columns = [
  {
    title: config[process.env.REACT_APP_SITE_ID].voterId,
    dataIndex: "registrationNumber",
    key: "registrationNumber",
    defaultSortOrder: "descend",
  },
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName",
    sortDirections: ["descend"],
  },
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName",
    defaultSortOrder: "descend",
  },
  {
    title: "Constituency",

    dataIndex: "pd.constituency.name",
    defaultSortOrder: "descend",
  },
  {
    title: "Role",
    dataIndex: "role.tag.name",
    key: "role.tag.name",
    sortDirections: ["descend"],
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Link to={`/voter/${record.registrationNumber}`}>View Profile</Link>
    ),
  },
];

const SearchContainer = ({ params, code, ...props }) => {
  const { data: user, loading: viewerLoader, error } = useQuery(
    GET_SEARCH_OPTIONS
  );
  const { data: taglist, ...tagsQuery } = useQuery(GET_TAGS, {
    variables: { code: "GOTV" },
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [getVoterDetails, { loading, data = [] }] = useLazyQuery(
    GET_GOTV_VOTERS
  );

  const { tags = [] } = queryString.parse(props.location.search, {
    arrayFormat: "index",
  });

  const [structure, setStructure] = useState({
    [STRUCTURE_MAP[props.structure]]: code,
    tags: tags,
  });

  const onSetStructure = (payload) => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
    setStructure({
      ...structure,
      ...payload,
    });
  };

  useEffect(
    () => {
      getVoterDetails({
        variables: {
          input: {
            ...structure,
            limit: pagination.pageSize,
            offset: (pagination.current - 1) * pagination.pageSize,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [structure, pagination]
  );

  useEffect(
    () => {
      if (data && data.getVotersWithRoles) {
        setPagination({
          ...pagination,
          total: data.getVotersWithRoles.count,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const generateReport = (type, name) => {
    var raw = JSON.stringify({
      type,
      name,
      structure: getLowestLevel(structure),
    });

    fetch(`${process.env.REACT_APP_API}/reports`, {
      body: raw,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getStoredUserAuth().token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("No voters match criteria or Error downloading report");
        }

        // Extract filename from header
        const filename = res.headers
          .get("content-disposition")
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        return res.blob().then((blob) => {
          saveFile(blob, filename);
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  if (viewerLoader || tagsQuery.loading) return <div>Loading....</div>;

  const { getVotersWithRoles: { rows = [] } = { rows: [] } } = data;

  return (
    <AdminScreen title="Ready Reports">
      <Layout>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {error ? (
            <Alert type="error">
              <p>
                An error has occurded. Please refresh and if the problem
                persists, contact support.
              </p>
            </Alert>
          ) : (
            <>
              <StructuresComponent
                onSelect={onSetStructure}
                value={structure}
                defaultRole={"PD"}
                structures={user.viewer.structures}
                components={{
                  region: { code: "id" },
                  constituency: { code: "id" },
                }}
              />
              <Flex justifyContent={"flex-end"}>
                <Box mr={[2]}>
                  <AddGotvRole
                    structure={structure}
                    tags={
                      taglist
                        ? taglist.tags.map((tag) => ({
                            value: tag.name,
                            id: tag.id,
                            label: tag.name,
                            ...STRUCTURE_LEVELS[tag.structure],
                          }))
                        : []
                    }
                    structures={user.viewer.structures}
                  />
                </Box>
                <Box mr={[2]}>
                  <Button
                    onClick={() => generateReport("summary", "GOTV-Summary")}
                  >
                    Download Ready Summary
                  </Button>
                </Box>
                <Box mr={[2]}>
                  <Button onClick={() => generateReport("list", "GOTV-List")}>
                    Download Ready List
                  </Button>
                </Box>
              </Flex>
              <Flex>
                <Form.Item
                  className="w-25"
                  label="First Name"
                  style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                >
                  <Input
                    placeholder="Search by first name"
                    value={structure.firstName}
                    onChange={(e) =>
                      onSetStructure({ firstName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  className="w-25"
                  label="Last name"
                  style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                >
                  <Input
                    placeholder="Search by last name"
                    value={structure.lastName}
                    onChange={(e) =>
                      onSetStructure({ lastName: e.target.value })
                    }
                  />
                </Form.Item>
                {taglist && (
                  <Form.Item
                    label="Tags"
                    className="w-25"
                    style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select tags"
                      value={structure.tags}
                      onChange={(value) => {
                        onSetStructure({
                          tags: value,
                        });
                      }}
                      optionLabelProp="label"
                      style={{ width: "100%" }}
                    >
                      {taglist.tags.map((tag) => (
                        <Select.Option value={tag.id} label={tag.name}>
                          {tag.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Flex>
              <Table
                loading={loading}
                className="table-tags"
                rowSelectionType="none"
                dataSource={rows}
                columns={columns}
                pagination={pagination}
                onChange={(pagination, filters, sorter) => {
                  setPagination(pagination);
                }}
              />
            </>
          )}
        </Content>
      </Layout>
    </AdminScreen>
  );
};

export default SearchContainer;

const GET_GOTV_VOTERS = gql`
  query($input: ReportingQueryInput) {
    getVotersWithRoles(input: $input) {
      rows {
        registrationNumber
        firstName
        lastName
        id
        pd: pollingDivision {
          constituency {
            name
          }
        }
        role {
          tag: role {
            name
          }
        }
      }
      count
    }
  }
`;

const GET_TAGS = gql`
  query getTags($code: String) {
    tags(code: $code) {
      id
      name
      code
      category
      structure
    }
  }
`;
