import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Row, Spin } from "antd";
import { Box } from "rebass";
import { groupBy } from "lodash";
import { LineGraph } from "./Graphs";
import CardComponent from "./ui/CardComponent";

const DailyCanvass = (props) => {
  const { loading, error, data } = useQuery(CANVASS_COUNT, {
    variables: {
      input: props.query,
    },
  });

  if (error) return `Error! ${error.message}`;

  if (loading || !data)
    return (
      <Box p={[10]} sx={{ textAlign: "center" }}>
        <Spin />
      </Box>
    );

  const {
    getCanvasCountsByStructure,
    getTotalPopulation,
    getCanvasCountsByGroup,
    email,
    mobile,
  } = data;

  const dailyCanvassData = groupBy(
    getCanvasCountsByStructure,
    (i) => i.structure.name
  );

  const population = groupBy(getTotalPopulation, (i) => i.structure.name);
  const canvassTotal = groupBy(getCanvasCountsByGroup, (i) => i.structure.name);
  const emailTotal = groupBy(email, (i) => i.structure.name);
  const mobileTotal = groupBy(mobile, (i) => i.structure.name);

  return (
    <Row>
      {dailyCanvassData &&
        Object.keys(dailyCanvassData)
          .sort((a, b) => {
            const [codeA] = a.split(":");
            const [codeB] = b.split(":");

            if (codeA < codeB) {
              return -1;
            }
            if (codeA > codeB) {
              return 1;
            }
            return 0;
          })
          .map((key) => {
            const total = dailyCanvassData[key].reduce(
              (result, item) => (result += item.count),
              0
            );

            return (
              <CardComponent
                title={`${props.title}: ${key}`}
                key={`${props.title}: ${key}`}
              >
                <LineGraph
                  total={total}
                  {...props}
                  tags={props.tags}
                  structure={key}
                  key={new Date()}
                  data={dailyCanvassData[key]}
                  canvassTotal={canvassTotal}
                  population={population}
                  emailTotal={emailTotal}
                  mobileTotal={mobileTotal}
                />
              </CardComponent>
            );
          })}
    </Row>
  );
};

export default DailyCanvass;

const CANVASS_COUNT = gql`
  query getCanvasCountsByStructure($input: ReportingQueryInput) {
    email: getCanvasContact(input: $input, type: email) {
      count
      tag
      structure {
        name
        code
        type
      }
    }
    mobile: getCanvasContact(input: $input, type: mobile) {
      count
      tag
      structure {
        name
        code
        type
      }
    }
    getCanvasCountsByStructure(input: $input) {
      count
      tag
      structure {
        name
        code
        type
      }
    }
    getTotalPopulation(input: $input) {
      count
      tag
      structure {
        name
        code
        type
      }
    }
    getCanvasCountsByGroup(input: $input) {
      count
      tag
      structure {
        name
        code
        type
      }
    }
  }
`;
