import React from "react";
import { Text, Box, Flex } from "rebass";
import { List, Button, Input, Form } from "antd";
import config from "../../common/config";

export const LabelContainer = (props) => (
  <Flex
    mb={[1]}
    {...props}
    sx={{
      flexDirection: "row",
      alignItems: "center",

      justifyContent: "space-between",
    }}
  >
    {props.children}
  </Flex>
);

const TagList = ({
  tags,
  value = [],
  onChange = () => {},
  multiple = false,
  limit,
  comments = {},
  onCommentSet = () => {},
  sort = false,
}) => {
  const canvassOptions = config[process.env.REACT_APP_SITE_ID].canvass;
  const onSelect = (item) => {
    if (value.includes(item.id)) {
      onCommentSet(item.id, "");
      onChange(value.filter((v) => v !== item.id));
    } else {
      if (multiple) {
        if (limit && value.length === limit) {
          return;
        }
        onChange([...value, item.id]);
      } else {
        onChange([item.id]);
      }
    }
  };

  const sortedTags = sort
    ? tags.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      )
    : tags;

  return (
    <div style={{ paddingBottom: "16px" }}>
      {tags.length ? (
        <div
          style={{
            ...(canvassOptions.overflow
              ? { background: "#fff", position: "sticky", top: 0 }
              : {}),
          }}
        >
          <strong>
            {tags[0].category}{" "}
            {multiple && limit ? `(Select Top ${limit})` : null}
          </strong>
        </div>
      ) : null}
      <List
        className="list-500"
        style={{
          ...(canvassOptions.overflow
            ? { maxHeight: "250px", overflow: "scroll" }
            : {}),
        }}
        dataSource={sortedTags}
        keyExtractor={(item) => item.id}
        renderItem={(tag) => {
          return (
            <Box py={[1]} sx={{ borderBottom: "1px solid #ddd" }}>
              <LabelContainer onClick={() => onSelect(tag)}>
                <Text>{tag.name.toUpperCase()}</Text>
                <Button
                  shape="circle"
                  size={"normal"}
                  icon={
                    value && value.length && value.includes(tag.id)
                      ? "check"
                      : ""
                  }
                  onClick={() => onSelect(tag)}
                />
              </LabelContainer>
              {tag.hasComment && value.includes(tag.id) ? (
                <Form.Item label={tag.commentDescription || "Comment"}>
                  <Input.TextArea
                    value={comments[tag.id] || ""}
                    onChange={(e) => onCommentSet(tag.id, e.target.value)}
                  />
                </Form.Item>
              ) : null}
            </Box>
          );
        }}
      />
    </div>
  );
};

TagList.defaultProps = {
  tags: [],
};

export default TagList;
