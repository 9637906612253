import React, { useEffect, useState } from "react";
import { Col, Form, Select, Spin } from "antd";
import ChartRenderer from "./components/ChartRenderer";
import Dashboard from "./components/Dashboard";
import DashboardItem from "./components/DashboardItem";
import { useQuery } from "react-apollo";
import { GET_SEARCH_OPTIONS, StructuresComponent } from "../Admin/UsersScreen";

import AdminScreen from "../Admin/AdminScreen";
import { navigate } from "@reach/router";
import {
  getLowestLevel,
  getStoredPD,
  setStoredStructure,
} from "../../common/utils";
import { Box } from "rebass";
import { useStructure } from "../../App";

const getBaseArray = (filters = [], groupby) => {
  return [
    {
      id: 0,
      name: "Election Numbers",
      vizState: {
        query: {
          measures: [
            "Voters.count",
            "Voters.voted",
            "Voters.supporterVoted",
            "Voters.opponentsVoted",
            "Voters.dnaVoted",
            "Voters.indVoted",
            "Voters.unknownsVoted",
          ],
          timeDimensions: [],
          order: {
            [`Voters.${groupby}`]: "asc",
          },
          dimensions: [`Voters.${groupby}`],
          filters: filters.map((f) => ({
            ...f,
            dimension: `Voters.${f.dimension}`,
          })),
          renewQuery: true,
        },
        chartType: "table",
        size: 24,
      },
    },
    {
      id: 1,
      name: "Supporter Stats",
      vizState: {
        query: {
          measures: [
            "Voters.supportersPopulation",
            "Voters.percentageVoted",
            "Voters.supporterVoted",
            "Voters.supportersNotVoted",
            "Voters.supportersNotVotedPercentage",
          ],
          timeDimensions: [],
          order: {
            [`Voters.${groupby}`]: "asc",
          },
          dimensions: [`Voters.${groupby}`],
          filters: filters.map((f) => ({
            ...f,
            dimension: `Voters.${f.dimension}`,
          })),
        },
        chartType: "table",
        size: 24,
      },
    },
    {
      id: 2,
      name: "Supporter Numbers",
      vizState: {
        query: {
          measures: ["Voters.supporterVoted", "Voters.supportersNotVoted"],
          timeDimensions: [],
          order: {
            [`Voters.${groupby}`]: "asc",
            [`Voters.${groupby}`]: "asc",
          },
          dimensions: [`Voters.${groupby}`],
          filters: filters.map((f) => ({
            ...f,
            dimension: `Voters.${f.dimension}`,
          })),
        },
        chartType: "bar",
      },
    },
  ];
};
const DashboardPage = () => {
  const userStructure = useStructure();

  const { data: user, loading: viewerLoader } = useQuery(GET_SEARCH_OPTIONS);
  const [structure, setStructure] = useState(getStoredPD(userStructure));

  const [items, setItems] = useState(getBaseArray([]));

  useEffect(() => {
    const getData = () => {
      const mapping = {
        national: "country",
        region: "country",
        constituency: "constName",
        pd: "pollingdivision",
        ps: "pollingstation",
      };

      const selected = getLowestLevel(structure);
      var key = Object.keys(selected)[0];
      var value = selected[key];

      const { groupby = mapping[key] } = structure;

      setItems(
        getBaseArray(
          key !== "region"
            ? [
                {
                  dimension: mapping[key],
                  operator: "equals",
                  values: [value],
                },
              ]
            : [],
          groupby
        )
      );
    };

    getData();
    setStoredStructure(structure);
  }, [structure]);

  const onSetStructure = (payload) => {
    setStructure({
      ...structure,
      ...payload,
    });
  };

  if (viewerLoader) return <Spin />;

  const dashboardItem = (item) => (
    <Col
      span={24}
      lg={12}
      key={item.id}
      style={{
        marginBottom: "24px",
      }}
    >
      <DashboardItem title={item.name}>
        <ChartRenderer vizState={item.vizState} />
      </DashboardItem>
    </Col>
  );

  const Empty = () => (
    <div
      style={{
        textAlign: "center",
        padding: 12,
      }}
    >
      <h2>
        There are no charts on this dashboard. Use Playground Build to add one.
      </h2>
    </div>
  );

  return (
    <AdminScreen title="Election" onBack={() => navigate("/gotv")}>
      <StructuresComponent
        onSelect={onSetStructure}
        value={structure}
        exclude={["RegionComponent", "ElectoralComponent"]}
        defaultRole={"CANVASSER"}
        showDefault={false}
        structures={user.viewer.structures}
        components={{
          national: { code: "name" },
          constituency: { code: "name" },
          pd: { code: "pdName" },
          ps: { code: "name" },
        }}
      />
      <Box px={[4]}>
        <Form.Item label="Group By" className="w-100">
          <Select
            value={structure.groupby}
            style={{ width: "100%" }}
            onChange={(value) => onSetStructure({ groupby: value })}
          >
            {structure.region ||
            structure.constituency ||
            structure.electoral ||
            structure.pd ? null : (
              <Select.Option value="country">Country</Select.Option>
            )}

            {structure.electoral || structure.pd ? null : (
              <Select.Option value="constName">Constituency</Select.Option>
            )}
            <Select.Option value="pollingdivision">
              Polling District
            </Select.Option>
            <Select.Option value="pollingstation">
              Polling Station
            </Select.Option>
          </Select>
        </Form.Item>
      </Box>

      {items.length ? (
        <Dashboard dashboardItems={items}>{items.map(dashboardItem)}</Dashboard>
      ) : (
        <Empty />
      )}
    </AdminScreen>
  );
};

export default DashboardPage;
