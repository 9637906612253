import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Spin, Statistic } from "antd";
import { Box, Flex, Heading } from "rebass";

const PreviousResult = ({ input = {}, parties = [], year }) => {
  const { loading, error, data } = useQuery(RESULTS_COUNT, {
    variables: {
      input: input,
    },
  });

  if (error) return `Error! ${error.message}`;

  if (loading || !data)
    return (
      <Box p={[10]} sx={{ textAlign: "center" }}>
        <Spin />
      </Box>
    );

  if (data.getPreviousResults && data.getPreviousResults.length) {
    return (
      <div>
        <Heading mb={[3]} size="h4">
          {year} Results
        </Heading>
        <Flex justifyContent="space-between">
          {parties.map((party) => {
            const result = data.getPreviousResults.find(
              (r) => r.party === party
            );
            if (!result) return <Statistic title={`${party}`} value={0} />;
            return <Statistic title={`${party}`} value={result.count} />;
          })}
        </Flex>
      </div>
    );
  }

  return null;
};

export default PreviousResult;

const RESULTS_COUNT = gql`
  query getPreviousResults($input: ReportingQueryInput) {
    getPreviousResults(input: $input) {
      count
      party: tag
    }
  }
`;
