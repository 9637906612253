import React from "react";
import ChartistGraph from "react-chartist";
import Chartist from "chartist";
import { Statistic } from "antd";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import { Flex } from "rebass";
// import { navigate } from "@reach/router";
import queryString from "query-string";

import { getLowestLevel } from "../../common/utils";

export const LineGraph = (props) => {
  var data = {
    series: [
      props.data.map(function(s) {
        // Meta is only needed if using the tooltip plugin.
        return { x: s.tag, y: s.count, meta: s.count };
      }),
    ],
  };

  var options = {
    // This is making a short trendline type graph.  It can be removed to make a full size graph with labels and grid lines.
    height: "500px",
    axisX: {
      type: Chartist.AutoScaleAxis,
      showGrid: true,

      // This is reformats the dates to a nice ledgeble format.  The width should be added to ensure that to many items aren't rendered and thus overlap.
      scaleMinSpace: 10,
      labelInterpolationFnc: function(value, index) {
        const d = new Date(value);
        var date = d.getDate();
        var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12

        return `${date}/${month}`;
      },

      // These options don't seam to have an effect.  As a result the graph won't alwayse be edge to edge as there is some magic formula that desides how to scale the X Axis.
      //low: data.transDate.valueOf(),
      //high: data[data.length -1].transDate.valueOf()
    },
    axisY: {
      showGrid: true,
      onlyInteger: true,
      // Turning off the units here to make it a smaller trendline.

      labelInterpolationFnc: function(value, index) {
        return value;
      },
    },
    chartPadding: {
      top: 50,
    },
    plugins: [],
  };

  var listener = {
    draw: function(data) {
      if (data.type === "bar") {
        data.element._node.onclick = () => {
          console.log("clicked");
        };

        data.element._node.onpointerover = (e) => {
          data.element.attr({
            style: "cursor: pointer;",
          });
        };
      }
    },
  };

  if (ChartistTooltip) {
    options.plugins.push(
      ChartistTooltip({
        anchorToPoint: true,
        tooltipFnc: (meta, value, ...rest) => {
          return `<span class="ct-tooltip-meta ct-tooltip-meta">${meta}</span>`;
        },
      })
    );
  }

  const ct = props.canvassTotal[props.structure]
    ? props.canvassTotal[props.structure][0].count
    : 0;

  return (
    <div>
      <ChartistGraph
        data={data}
        options={options}
        type={"Bar"}
        listener={listener}
      />
      <Flex justifyContent="space-between">
        <Statistic title="Total Canvasses" value={ct} />
        <Statistic
          title="Population"
          value={props.population[props.structure][0].count}
        />
        <Statistic
          title="Emails"
          value={
            props.emailTotal[props.structure]
              ? props.emailTotal[props.structure][0].count
              : 0
          }
        />
        <Statistic
          title="Cellphone"
          value={
            props.mobileTotal[props.structure]
              ? props.mobileTotal[props.structure][0].count
              : 0
          }
        />
        <Statistic
          title="% Canvassed"
          value={Math.round(
            (ct / props.population[props.structure][0].count) * 100
          )}
        />
      </Flex>
    </div>
  );
};

export const BarGraph = (props) => {
  var data = {
    labels: props.data.map((s) => s.tag),
    series: [
      props.data.map((s) => {
        const tagData = props.tags.find((tag) => tag.name === s.tag);

        return {
          meta: {
            ...(tagData || {}),
            structure: s.structure,
            count: s.count,
          },
          data: s.count,
        };
      }),
    ],
  };

  var options = {
    horizontalBars: true,
    seriesBarDistance: 10,
    onlyInteger: true,
    height: "500px",
    axisX: {
      offset: 60,
    },
    axisY: {
      offset: 80,

      labelInterpolationFnc: function(value) {
        return value;
      },
      scaleMinSpace: 15,
    },
    chartPadding: {
      right: 50,
      left: 50,
    },
    plugins: [],
  };

  if (ChartistTooltip) {
    options.plugins.push(
      ChartistTooltip({
        anchorToPoint: true,
        tooltipFnc: (meta, value, ...rest) => {
          return `<span class="ct-tooltip-meta ct-tooltip-meta">${value}</span>`;
        },
      })
    );
  }

  return (
    <>
      <ChartistGraph
        listener={{
          draw: function(t) {
            if (t.type === "bar") {
              t.element._node.onclick = () => {
                let params = props.query;
                params.tags = [t.meta.id];

                params.total = t.meta.count;

                const stringified = queryString.stringify(params, {
                  arrayFormat: "index",
                });

                const structure = getLowestLevel(params);
                const [type] = Object.keys(structure);
                const value = structure[type];

                window.open(`/voters/${type}/${value}?${stringified}`);
              };

              if (t.meta && t.meta.color) {
                t.element.attr({
                  style: "stroke: " + t.meta.color + ";",
                });
              }

              t.element._node.onpointerleave = (e) => {};

              t.element._node.onpointerover = (e) => {
                t.element.attr({
                  style: "cursor: pointer; stroke: " + t.meta.color + ";",
                });
              };
            }
          },
        }}
        data={data}
        options={options}
        type={"Bar"}
      />
    </>
  );
};

export const PieGraph = (props) => {
  const data = {
    series: props.data.map((r, index) => ({
      value: r.count,
      name: `${r.tag} (${r.count})`,
      className: `ct-series ct-pie-${index}`,
    })),
  };

  const options = {
    showLabel: false,
    plugins: [Chartist.plugins.legend()],
  };

  const responsiveOptions = [
    [
      "screen and (min-width: 768px)",
      {
        height: "500px",
      },
    ],
    [
      "screen and (max-width: 768px)",
      {
        height: "300px",
      },
    ],
  ];

  return (
    <div>
      <ChartistGraph
        data={data}
        options={options}
        responsiveOptions={responsiveOptions}
        type={"Pie"}
      />
    </div>
  );
};

export const SimpleLineGraph = ({ data, high, showPercentage, children }) => {
  var options = {
    height: "500px",
    axisY: {
      onlyInteger: true,
      maximum: 100,
      labelInterpolationFnc: function(value, index) {
        return `${value}${showPercentage ? "%" : ""}`;
      },
    },

    high: high ? high : null,
  };

  var type = "Line";

  return (
    <div>
      <ChartistGraph data={data} type={type} options={options} />
      {children}
    </div>
  );
};
