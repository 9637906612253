import React, { useContext } from "react";
import { navigate } from "@reach/router";
import { Spin, Button } from "antd";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Text, Box, Flex } from "rebass";
import { ResultsContext } from "../Search/ResultsProvider";

import { groupBy } from "lodash";
import { PrivateRoleRoute } from "../../App";
import config from "../../common/config";
import AdminScreen from "../Admin/AdminScreen";

export const LabelContainer = (props) => (
  <Flex
    {...props}
    py={[3]}
    flexDirection={["column", "column", "row"]}
    alignItems={["left", "left", "center"]}
    sx={{
      borderBottom: "1px solid #ddd",
      justifyContent: "space-between",
      maxHeight: "300px",
      overflow: "auto",
    }}
  >
    {props.children}
  </Flex>
);

const VoterProfileScreen = (props) => {
  const { setVoter } = useContext(ResultsContext);
  const { loading, error, data } = useQuery(GET_VOTER, {
    variables: { registrationNumber: props.registrationNumber },
  });

  if (error) return <p>{error}</p>;
  if (loading) return <Spin />;

  const { voter } = data;

  const onStartCanvass = () => {
    setVoter(voter);
    navigate(`/voter/${voter.registrationNumber}/canvass`);
  };

  const onStartContact = () => {
    setVoter(voter);
    navigate(`/voter/${voter.registrationNumber}/contact`);
  };

  const onStartCapture = () => {
    setVoter(voter);
    navigate(`/voter/${voter.registrationNumber}/capture`);
  };

  const groupedTags = groupBy(voter.tags, "category");

  const stream = voter.pollingDivision.streams.find((stream) => {
    return stream.surnames.includes(voter.lastName.charAt(0).toUpperCase());
  });

  return (
    <AdminScreen
      title={`${voter.firstName} ${voter.lastName}`}
      onBack={() => navigate("/results")}
    >
      <Box mt={[3]} mx="auto" px={[3]} sx={{ maxWidth: "100%" }}>
        <LabelContainer>
          <div>
            <Text fontWeight="bold">First Name</Text>
          </div>
          <div>
            <Text color="#777777">{voter.firstName}</Text>
          </div>
        </LabelContainer>
        <LabelContainer>
          <div>
            <Text fontWeight="bold">Last Name</Text>
          </div>
          <div>
            <Text color="#777777">{voter.lastName}</Text>
          </div>
        </LabelContainer>
        {voter.middleName ? (
          <LabelContainer>
            <div>
              <Text fontWeight="bold">Middle Name</Text>
            </div>
            <div>
              <Text color="#777777">{voter.middleName}</Text>
            </div>
          </LabelContainer>
        ) : null}
        {voter.alias ? (
          <LabelContainer>
            <div>
              <Text fontWeight="bold">Alias</Text>
            </div>
            <div>
              <Text color="#777777">{voter.alias}</Text>
            </div>
          </LabelContainer>
        ) : null}
        {voter.gender ? (
          <LabelContainer>
            <div>
              <Text fontWeight="bold">Gender</Text>
            </div>
            <div>
              <Text color="#777777">{voter.gender}</Text>
            </div>
          </LabelContainer>
        ) : null}
        <LabelContainer>
          <div>
            <Text fontWeight="bold">
              {config[process.env.REACT_APP_SITE_ID].voterId}
            </Text>
          </div>
          <div>
            <Text color="#777777">{voter.registrationNumber}</Text>
          </div>
        </LabelContainer>
        {voter.contact && voter.contact.length
          ? voter.contact.map((c) => (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">{c.type.toUpperCase()}</Text>
                </div>
                <div>
                  <Text color="#777777">{c.value}</Text>
                </div>
              </LabelContainer>
            ))
          : null}
        <LabelContainer>
          <div>
            <Text fontWeight="bold">Address</Text>
          </div>
          <div>
            <Text color="#777777">
              {voter.address
                ? Object.keys(voter.address)
                    .map((a) => {
                      if (a !== "__typename") return voter.address[a];
                      return null;
                    })
                    .join(" ")
                : null}
            </Text>
          </div>
        </LabelContainer>
        {voter.profession ? (
          <LabelContainer>
            <div>
              <Text fontWeight="bold">Occupation</Text>
            </div>
            <div>
              <Text color="#777777">{voter.profession}</Text>
            </div>
          </LabelContainer>
        ) : null}
        {voter.pollingDivision ? (
          <React.Fragment>
            <LabelContainer>
              <div>
                <Text fontWeight="bold">Polling Division</Text>
              </div>
              <div>
                <Text color="#777777">
                  {voter.pollingDivision.pdName || voter.pollingDivision.pdCode}
                </Text>
              </div>
            </LabelContainer>
            <LabelContainer>
              <div>
                <Text fontWeight="bold">Constituency</Text>
              </div>
              <div>
                <Text color="#777777">
                  {voter.pollingDivision.constituency?.name ?? "-"}
                </Text>
              </div>
            </LabelContainer>
            {voter.pollingDivision.electoralDivision ? (
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">Electoral Division</Text>
                </div>
                <div>
                  <Text color="#777777">
                    {voter.pollingDivision.electoralDivision.name}
                  </Text>
                </div>
              </LabelContainer>
            ) : null}
          </React.Fragment>
        ) : null}
        {stream ? (
          <LabelContainer>
            <div>
              <Text fontWeight="bold">Polling Station</Text>
            </div>
            <div>
              <Text color="#777777">
                {stream.name} | {stream.location} | {stream.address}
              </Text>
            </div>
          </LabelContainer>
        ) : null}
        <Box mt={[4]}>
          <Text fontWeight="bold">Canvass Information</Text>
          <hr />
          {voter.lastAffiliation ? (
            <>
              <LabelContainer>
                <div>
                  <Text fontWeight="bold">Affiliation</Text>
                </div>
                <div>
                  <Text color="#777777" fontWeight="bold">
                    {voter.lastAffiliation}
                  </Text>
                </div>
              </LabelContainer>
            </>
          ) : null}
          {voter.tags ? (
            <>
              {Object.keys(groupedTags).map((p) => (
                <LabelContainer>
                  <div>
                    <Text fontWeight="bold">{p}</Text>
                  </div>
                  <div>
                    <Text color="#777777" fontWeight="bold">
                      {groupedTags[p][0].name}
                    </Text>
                  </div>
                </LabelContainer>
              ))}
            </>
          ) : null}

          {voter.updatedAt ? (
            <LabelContainer>
              <div>
                <Text fontWeight="bold">Last Updated</Text>
              </div>
              <div>
                <Text color="#777777" fontWeight="bold">
                  {voter.updatedAt}
                </Text>
              </div>
            </LabelContainer>
          ) : null}
          {voter.comments && voter.comments.length ? (
            <LabelContainer>
              <div>
                <Text fontWeight="bold">Comments</Text>
                {voter.comments.map(({ text }) => (
                  <Text size="small">{text}</Text>
                ))}
              </div>
            </LabelContainer>
          ) : null}
        </Box>
        <Box py={[4]}>
          {config[process.env.REACT_APP_SITE_ID].canvass ? (
            <PrivateRoleRoute
              key={`${new Date()}-admin`}
              role={"Canvassing"}
              as={React.Fragment}
            >
              <Box mb={[2]}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={onStartCanvass}
                >
                  {config[process.env.REACT_APP_SITE_ID].canvass.label}
                </Button>
              </Box>
            </PrivateRoleRoute>
          ) : null}

          {config[process.env.REACT_APP_SITE_ID].capture ? (
            <PrivateRoleRoute
              key={`${new Date()}-admin`}
              role={"Canvassing"}
              as={React.Fragment}
            >
              <Box mb={[2]}>
                <Button
                  block
                  type="secondary"
                  size="large"
                  onClick={onStartCapture}
                >
                  {config[process.env.REACT_APP_SITE_ID].capture.label}
                </Button>
              </Box>
            </PrivateRoleRoute>
          ) : null}

          <PrivateRoleRoute
            key={`${new Date()}-admin`}
            role={"Canvassing"}
            as={React.Fragment}
          >
            <Box mb={[2]}>
              <Button
                block
                type="secondary"
                size="large"
                onClick={onStartContact}
              >
                Update Contact
              </Button>
            </Box>
          </PrivateRoleRoute>
        </Box>
      </Box>
    </AdminScreen>
  );
};

export default VoterProfileScreen;

export const GET_VOTER = gql`
  query getVoter($registrationNumber: String!) {
    voter(registrationNumber: $registrationNumber) {
      id
      firstName
      middleName
      lastName
      registrationNumber
      voterId
      alias
      gender
      comments {
        text
        createdAt
      }
      contact {
        id
        value
        type
      }
      tags {
        code
      }
      gender
      address {
        addressLine1
        addressLine2
        postalCode
      }
      updatedAt: modifiedAt
      profession
      pollingDivision {
        id
        constituency {
          name
        }
        electoralDivision {
          name
        }
        pdCode
        pdName
        streams {
          surnames
          name
          location
          address
        }
      }
      tags {
        category
        name
      }
      lastAffiliation
    }
  }
`;
