import React, { useEffect, useState } from "react";
import { Table, Form, Input, Layout, Alert, Button, Tabs, Tag } from "antd";

import queryString from "query-string";
import gql from "graphql-tag";
import { useLazyQuery, useQuery, useMutation } from "react-apollo";
import { StructuresComponent, GET_SEARCH_OPTIONS } from "../Admin/UsersScreen";
import { STRUCTURE_MAP } from "../Reporting/ReportingStructure";
import { Flex } from "rebass";
import AdminScreen from "../Admin/AdminScreen";

const { Content } = Layout;

const columns = [
  {
    title: "First name",
    dataIndex: "data.firstName",
    key: "firstName",
    sortDirections: ["descend"],
  },
  {
    title: "Last name",
    dataIndex: "data.lastName",
    key: "lastName",
    defaultSortOrder: "descend",
  },
  {
    title: "Profession",
    dataIndex: "data.profession",
    key: "profession",
    defaultSortOrder: "descend",
  },
  {
    title: "Street Name",
    dataIndex: "data.streetName",
    key: "streetName",
    defaultSortOrder: "descend",
  },
  {
    title: "Status",
    key: "status",
    render: (_, record) => (
      <>
        {!record.voter ? <Tag color="red">No Voter Assigned</Tag> : null}
        {record.status !== "completed" ? (
          <Tag color="red">Submit Canvass</Tag>
        ) : (
          <Tag color="green">Completed</Tag>
        )}
      </>
    ),
  },
];

const OfflineContainer = ({ params, code, ...props }) => {
  const { data: user, loading: viewerLoader, error } = useQuery(
    GET_SEARCH_OPTIONS
  );
  const { data: taglist, ...tagsQuery } = useQuery(GET_TAGS, {});

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [getVoterDetails, { loading, data = [], refetch }] = useLazyQuery(
    GET_OFFLINE_CANVASSES
  );

  const { tags = [] } = queryString.parse(props.location.search, {
    arrayFormat: "index",
  });

  const [structure, setStructure] = useState({
    [STRUCTURE_MAP[props.structure]]: code,
    tags: tags,
  });

  const onSetStructure = (payload) => {
    setPagination({
      current: 1,
      pageSize: 10,
    });
    setStructure({
      ...structure,
      ...payload,
    });
  };

  useEffect(
    () => {
      getVoterDetails({
        variables: {
          input: {
            ...structure,
            limit: pagination.pageSize,
            offset: (pagination.current - 1) * pagination.pageSize,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [structure, pagination]
  );

  useEffect(
    () => {
      if (data && data.getOfflineCanvasses) {
        setPagination({
          ...pagination,
          total: data.getOfflineCanvasses.count,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );
  const [assignVoter] = useMutation(ASSIGN_CANVASS_VOTER);
  const [submitOffline] = useMutation(SUBMIT_OFFLINE_RECORD);

  const assignVoterToCanvass = (id, voter) => {
    assignVoter({ variables: { id, voter } }).then(() => {
      refetch();
    });
  };
  if (viewerLoader || tagsQuery.loading) return <div>Loading....</div>;

  const { getOfflineCanvasses: { rows = [] } = { rows: [] } } = data;
  console.log(rows);
  return (
    <AdminScreen title="Offline Management">
      <Layout>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {error ? (
            <Alert type="error">
              <p>
                An error has occurded. Please refresh and if the problem
                persists, contact support.
              </p>
            </Alert>
          ) : (
            <>
              <StructuresComponent
                onSelect={onSetStructure}
                value={structure}
                defaultRole={"PD"}
                structures={user.viewer.structures}
                components={{
                  region: { code: "id" },
                  constituency: { code: "id" },
                }}
              />
              <Flex>
                <Form.Item
                  className="w-25"
                  label="First Name"
                  style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                >
                  <Input
                    placeholder="Search by first name"
                    value={structure.firstName}
                    onChange={(e) =>
                      onSetStructure({ firstName: e.target.value })
                    }
                  />
                </Form.Item>
                <Form.Item
                  className="w-25"
                  label="Last name"
                  style={{ flex: "1 0 auto", paddingLeft: "8px" }}
                >
                  <Input
                    placeholder="Search by last name"
                    value={structure.lastName}
                    onChange={(e) =>
                      onSetStructure({ lastName: e.target.value })
                    }
                  />
                </Form.Item>
              </Flex>
              <Table
                loading={loading}
                className="table-tags"
                rowSelectionType="none"
                dataSource={rows}
                columns={columns}
                pagination={pagination}
                expandedRowRender={(record) => {
                  if (record.status === "completed") return null;
                  return (
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane tab="Match Voter" key="1">
                        <MatchingVoters
                          voter={record.voter}
                          rows={record.matching}
                          onAssign={(voterId) =>
                            assignVoterToCanvass(record.id, voterId)
                          }
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Conflicts" key="2">
                        {!record.voter ? (
                          <Alert
                            type="warning"
                            message="A matching voter is required"
                          />
                        ) : null}

                        {record.voter ? (
                          <CurrentTags
                            tags={taglist.tags}
                            current={record.voter}
                            data={record.data}
                            onSubmit={(payload) =>
                              submitOffline({
                                variables: { id: record.id, payload },
                              }).then(() => refetch())
                            }
                          />
                        ) : null}
                      </Tabs.TabPane>
                    </Tabs>
                  );
                }}
                onChange={(pagination, filters, sorter) => {
                  setPagination(pagination);
                }}
              />
            </>
          )}
        </Content>
      </Layout>
    </AdminScreen>
  );
};

export default OfflineContainer;

const CurrentTags = ({ current, data, tags, onSubmit }) => {
  const [merge, setMerge] = useState({});

  const tagDifference = current.tags.reduce((result, item) => {
    if (!data.tags.includes(item.id)) {
      result.push(item.id);
    }

    return result;
  }, []);

  const paramDifference = Object.keys(current)
    .filter((p) => !["id", "tags", "firstName", "lastName"].includes(p))
    .reduce((result, item) => {
      if (data[item] && data[item] !== current[item]) {
        result.push(item);
      }

      return result;
    }, []);

  const onSetParam = (payload) => {
    setMerge({
      ...merge,
      ...payload,
    });
  };

  let canSumbit = true;

  if (tagDifference.length && !merge.tags) {
    canSumbit = false;
  }

  if (paramDifference.length) {
    paramDifference.forEach((param) => {
      if (!merge[param]) {
        canSumbit = false;
      }
    });
  }

  return (
    <>
      {canSumbit ? (
        <Button onClick={() => onSubmit(merge)}>Submit Canvass</Button>
      ) : (
        <Alert message={"Resolve all conflicts in order to merge changes"} />
      )}

      <table>
        <thead className="ant-table-thead">
          <tr>
            <th>Parameter</th>
            <th>Current</th>
            <th>Offline</th>
            <th>Difference</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          <tr>
            {tagDifference.length ? (
              <>
                <td>Tags</td>
                <td>
                  {current.tags.map((p) => (
                    <p>{p.name}</p>
                  ))}
                </td>
                <td>
                  {data.tags.map((p) => (
                    <p>{tags.find((tag) => tag.id === p)?.name}</p>
                  ))}
                </td>
                <td>
                  {tagDifference.map((p) => (
                    <p>{tags.find((tag) => tag.id === p)?.name}</p>
                  ))}
                </td>
                <td>
                  <Button
                    size="small"
                    type={merge.tags === "current" ? "primary" : null}
                    style={{ marginRight: "10px" }}
                    onClick={() => onSetParam({ tags: "current" })}
                  >
                    Use Current
                  </Button>
                  <Button
                    size="small"
                    type={merge.tags === "offline" ? "primary" : null}
                    style={{ marginRight: "10px" }}
                    onClick={() => onSetParam({ tags: "offline" })}
                  >
                    Use Offline
                  </Button>
                  <Button
                    size="small"
                    style={{ marginRight: "10px" }}
                    type={merge.tags === "merge" ? "primary" : null}
                    onClick={() => onSetParam({ tags: "merge" })}
                  >
                    Merge
                  </Button>
                </td>
              </>
            ) : null}
          </tr>

          {paramDifference.length
            ? paramDifference.map((param) => (
                <tr>
                  <td>{param.toUpperCase()}</td>
                  <td>{current[param]}</td>
                  <td>{data[param]}</td>
                  <td />
                  <td>
                    <Button
                      size="small"
                      style={{ marginRight: "10px" }}
                      type={merge[param] === "current" ? "primary" : null}
                      onClick={() => onSetParam({ [param]: "current" })}
                    >
                      Use Current
                    </Button>
                    <Button
                      size="small"
                      style={{ marginRight: "10px" }}
                      type={merge[param] === "offline" ? "primary" : null}
                      onClick={() => onSetParam({ [param]: "offline" })}
                    >
                      Use Offline
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </>
  );
};

OfflineContainer.fragments = {
  offline: gql`
    fragment OfflineCanvassInfo on OfflineCanvass {
      id
      data
      status
      user {
        registrationNumber
      }
      matching {
        id
        registrationNumber
        firstName
        lastName
        profession
        pollingDivision {
          id
        }
        address {
          addressLine2
        }
      }
      voter {
        id
        registrationNumber
        firstName
        lastName
        profession
        address {
          addressLine2
        }
        tags {
          id
          name
        }
        contact {
          type
          value
        }
        comments {
          text
        }
        alias
      }
      hasLaterCanvass
    }
  `,
};

const GET_OFFLINE_CANVASSES = gql`
  query($input: ReportingQueryInput) {
    getOfflineCanvasses(input: $input) {
      count
      rows {
        ...OfflineCanvassInfo
      }
    }
  }
  ${OfflineContainer.fragments.offline}
`;

const GET_TAGS = gql`
  query getTags {
    tags {
      id
      name
      code
      category
      structure
    }
  }
`;

const ASSIGN_CANVASS_VOTER = gql`
  mutation matchOfflineCanvasses($id: ID!, $voter: ID!) {
    matchOfflineCanvasses(id: $id, voter: $voter)
  }
`;

const SUBMIT_OFFLINE_RECORD = gql`
  mutation submitOfflineCanvass($id: ID!, $payload: Json!) {
    submitOfflineCanvass(id: $id, payload: $payload)
  }
`;

const MatchingVoters = ({ rows: data, onAssign, voter }) => {
  const columns = [
    {
      title: "Registration Number",
      dataIndex: "registrationNumber",
      key: "registrationNumber",
      sortDirections: ["descend"],
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      sortDirections: ["descend"],
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      defaultSortOrder: "descend",
    },
    {
      title: "Profession",
      dataIndex: "profession",
      key: "profession",
      defaultSortOrder: "descend",
    },
    {
      title: "Street Name",
      dataIndex: "address.addressLine2",
      key: "streetName",
      defaultSortOrder: "descend",
    },
    {
      title: "PollingDivision",

      dataIndex: "poll.id",
      defaultSortOrder: "descend",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) =>
        voter && voter.id === record.id ? (
          "Assigned"
        ) : (
          <Button onClick={() => onAssign(record.id)}>Assign Voter</Button>
        ),
    },
  ];

  return (
    <>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>
    </>
  );
};
