import React, { useContext } from "react";
import { navigate } from "@reach/router";
import { Button } from "antd";
import { authContext } from "./Auth";

const Logout = (props) => {
  const { setUnauthStatus, setApplicationToken } = useContext(authContext);

  const onLogout = (e) => {
    setApplicationToken(null);
    setUnauthStatus();

    navigate("/login");
  };

  return (
    <Button
      block
      onClick={onLogout}
      size="large"
      type="primary"
      style={{ marginTop: "1rem" }}
    >
      Logout
    </Button>
  );
};

export default Logout;
